import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import './signReviewPopup.css';

const SignReviewPopup = ({ isSignModalOpen, cityDetails = {}, handleCancel, onSiteSubmit, permitValues }) => {
    const [showLearnMore, setShowLearnMore] = useState(false)
    const [siteReview, setSiteReview] = useState(false)
    const [signRemoval, setSignRemoval] = useState(false)

    useEffect(() => {
        setSiteReview(permitValues.site_review)
        setSignRemoval(permitValues.sign_removal)
    }, [permitValues])

    const handleChange = (e) => {
        if (e.target.name === 'sign_removal') {
            if (e.target.value === 'Yes' && e.target.checked) {
                setSignRemoval(e.target.checked)
            } else {
                setSignRemoval(false)
            }
        }
        if (e.target.name === 'site_review') {
            if (e.target.value === 'Yes' && e.target.checked) {
                setSiteReview(e.target.checked)
            } else {
                setSiteReview(false)
            }
        }
    }

    const handleSubmit = () => {
        let values = { site_review: siteReview, sign_removal: signRemoval }
        onSiteSubmit(values)
    }

    const handleClose = () => {
        setSiteReview(permitValues.site_review)
        setSignRemoval(permitValues.sign_removal)
        handleCancel()
    }

    return (
        <>
            <Modal
                footer={null}
                title={`${cityDetails.city_name}: Optional Item`}
                open={isSignModalOpen}
                onOk={handleClose}
                onCancel={handleClose}
                width={600}
            >
                <div>
                    <form name="signAndSiteReview" id="signAndSiteReview" className="basic-form">
                        <div className="popup-content">
                            {cityDetails.site_review ?
                                <div className="signandreview-block" ng-show="permit.showSiteReview">
                                    <div className="signandreview-head">Site Review:</div>
                                    <p>Do you want us to perform a thorough review of the permit address and to advise the most convenient, suitable, legitimate area for the permit?</p>
                                    <div>
                                        <label className="radio-btn">
                                            <input onChange={handleChange} type="radio" name="site_review" value="Yes" checked={siteReview} ng-model="permit.site_review" />
                                            <span className='radio-text'>{cityDetails.sitereview_yes}</span>
                                        </label>
                                        <label className="radio-btn">
                                            <input onChange={handleChange} type="radio" name="site_review" value="No" checked={!siteReview} ng-model="permit.site_review" />
                                            <span className='radio-text'>{cityDetails.sitereview_no}</span>
                                        </label>
                                    </div>
                                </div> : null}
                            {cityDetails.sign_removal ?
                                <div className="signandreview-block" ng-show="permit.showsignRemoval">
                                    <div className="signandreview-head">Sign Removal:</div>
                                    <p>The City and your neighbors want the “tow zone” signs taken down upon completion of the job. Either take them down or hire us to do it for you.</p>
                                    {!showLearnMore ?
                                        <span onClick={() => setShowLearnMore(true)} className="learn-more">(Why are we doing this? Learn more.)</span>
                                        : null}
                                    <label className="radio-btn">
                                        <input onChange={handleChange} ng-disabled="disableSignRemoveBtn" type="radio" name="sign_removal" value="Yes" checked={signRemoval} />
                                        <span className='radio-text'>{cityDetails.signremoval_note}</span>
                                    </label>
                                    <label className="radio-btn">
                                        <input onChange={handleChange} ng-disabled="disableSignRemoveBtn" type="radio" name="sign_removal" value="No" checked={!signRemoval} />
                                        <span className='radio-text'>{cityDetails.signremoval_penalty_note}</span>
                                    </label>
                                </div> : null}
                            {showLearnMore ?
                                <div className="signandreview-block" ng-show="permit.showLearnMore">
                                    <div onClick={() => setShowLearnMore(false)} className="close-icon popup-header">
                                        <span className="mpIcons-close popup-close" ng-click="hideLearnmore()"></span>
                                    </div>
                                    <div className="learn-more-text">Learn more: </div>
                                    <p>{cityDetails.signremoval_learnmore}</p>
                                </div> : null}
                        </div>
                        <footer className="popup-footer">
                            <button className="btn secondary-btn submit-btn xs-rounded-btn" type="button" onClick={handleSubmit}>Submit</button>
                            <button type="button" className="btn cancel-btn" onClick={handleClose}>Cancel</button>
                        </footer>
                    </form>
                </div>
            </Modal>
        </>
    );
};
export default SignReviewPopup;
