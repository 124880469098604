import React, { useEffect, useState } from 'react'
import './permitInformationForm.css';
import { DatePicker } from 'antd';
import { useDispatch } from "react-redux";
import { getErrors, processDatas } from './permitFormUtils'
import moment from 'moment';
import { getCityDetails } from "../../actions/permits";
import RushHourPopup from "../rushHourPopup/rushHourPopup";
import CityDetailsPopup from "../cityDetailsPopup/cityDetailsPopup";
import SignReviewPopup from "../signReviewPopup/signReviewPopup";
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)

const PermitInformationForm = ({
    reasons = [],
    me,
    cities = [],
    handleSubmit,
    customerProfileId,
    cityDetails = {},
    permitDetails = {},
    submitButtonText = 'Continue',
    type,
    quickPermit,
    isLoggedIn
}) => {

    const [permitValues, setPermitValues] = useState({ reason_id: '52', sign_removal: false, site_review: false });
    const [errList, setErrList] = useState({});
    const [isRushModalOpen, setIsRushModalOpen] = useState(false);
    const [isSignModalOpen, setIsSignModalOpen] = useState(false);
    const [isRush, setIsRush] = useState(false);
    const [diffDaysNumber, setDiffDaysNumber] = useState(null);
    const [isCityPopupOpen, setIsCityPopupOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);


    const dispatch = useDispatch();

    useEffect(() => {
        console.log('permitDetails ========, ', permitDetails)
        if (permitDetails && (typeof permitDetails === "object") && (Object.keys(permitDetails).length !== 0)) {
            let prefilledDatas = {
                city_id: permitDetails.city_id,
                street_address: permitDetails.street_address,
                apartment: permitDetails.apartment,
                zipcode: permitDetails.zip_code || permitDetails.zipcode,
                start_at: permitDetails.start_at ? moment(permitDetails.start_at, 'YYYY-MM-DD').format('MM-DD-YYYY') : '',
                end_at: permitDetails.end_at ? moment(permitDetails.end_at, 'YYYY-MM-DD').format('MM-DD-YYYY') : '',
                reason_id: permitDetails.reason_id,
                first_name: permitDetails.first_name,
                last_name: permitDetails.last_name,
                billing_address: permitDetails.billing_address,
                billing_city: permitDetails.billing_city,
                billing_zipcode: permitDetails.billing_zipcode,
                billing_phone: permitDetails.billing_phone,
                billing_phone_ext: permitDetails.billing_phone_ext,
                billing_fax: permitDetails.billing_fax,
                created_from: "",
                state_id: permitDetails.state_id,
                paymentMethod: permitDetails.payment_type === 'invoice' ? true : false,
                source: "MP",
                site_review: permitDetails.site_review,
                sign_removal: permitDetails.sign_removal,
                meter_numbers: permitDetails.meter_numbers,
                shipper_name: permitDetails.shipper_name,
                special_requirements: permitDetails.special_requirements,
                company_name: permitDetails.company_name,
                authorize_card_id: permitDetails.authorize_card_id,
                authorize_customer_id: permitDetails.authorize_customer_id,
                user_id: permitDetails.user_id,
                card_number: '',
                card_expiration_date: '',
                termsandservice: quickPermit ? false : true,
                payment_method_token: permitDetails.authorize_customer_id,
                customerPaymentProfileId: permitDetails.authorize_card_id,
            }
            if (permitDetails.start_at) {
                let date1 = moment(permitDetails.start_at, 'YYYY-MM-DD');
                let date2 = moment();
                let diffDays = date1.diff(date2, 'days')
                console.log('diffDays start_at=======', diffDays)
                if (diffDays < 4) {
                    setIsRush(true)
                }
            }
            if (quickPermit) {
                prefilledDatas.quick_permit = 1
            }
            if (permitDetails.city_id) {
                dispatch(getCityDetails(permitDetails.city_id));
            }
            if (permitDetails.end_at) {
                let date1 = moment(permitDetails.end_at);
                let date2 = moment(permitDetails.start_at);
                let diffDays = date1.diff(date2, 'days')
                console.log('diffDays end_at=======', diffDays)
                setDiffDaysNumber(diffDays + 1)
            }
            setPermitValues({ ...permitValues, ...prefilledDatas })
        } else {
            if (me) {
                let customerDatas = {
                    first_name: me?.UserOption?.first_name,
                    last_name: me?.UserOption?.last_name,
                    billing_address: me?.UserOption?.mailing_address,
                    billing_city: me?.UserOption?.mailing_city,
                    billing_phone_ext: me?.UserOption?.phone_ext,
                    billing_fax: me?.UserOption?.fax,
                    billing_zipcode: me?.UserOption?.mailing_zip,
                    state_id: me?.UserOption?.mailing_state,
                    billing_phone: me?.UserOption?.phone,
                    company_name: me?.UserOption?.company_name,
                    card_number: '',
                    card_expiration_date: ''
                }
                setPermitValues({ ...permitValues, ...customerDatas })
            }
        }
    }, [me, permitDetails])

    const handleChange = (e) => {
        if (e.target.name === 'city_id') {
            dispatch(getCityDetails(e.target.value));
        }
        if (e.target.name === 'card_number') {
            if (e.target.value.match("^[0-9 -]*$") !== null) {
                let value = e.target.value
                if (e.target.value.length === 4 || e.target.value.length === 9 || e.target.value.length === 14) {
                    value = `${e.target.value}-`
                }
                setPermitValues({ ...permitValues, [e.target.name]: value })
            }
        } else if (e.target.name === 'card_expiration_date') {
            if (e.target.value.match("^[0-9  / ]*$") !== null) {
                let value = e.target.value
                if (e.target.value.length === 2) {
                    value = `${e.target.value} / `
                }
                setPermitValues({ ...permitValues, [e.target.name]: value })
            }
        } else if (e.target.name === 'card_security_code') {
            if (e.target.value.match("^[0-9]*$") !== null) {
                setPermitValues({ ...permitValues, [e.target.name]: e.target.value })
            }
        } else {
            setPermitValues({ ...permitValues, [e.target.name]: e.target.value })
        }
    }

    const handleCheckBoxChange = (e) => {
        setPermitValues({ ...permitValues, [e.target.name]: e.target.checked })
    }

    const handleSiteSignChange = (data) => {
        setPermitValues({ ...permitValues, ...data })
        setIsSignModalOpen(false)
    }

    const addWeekdays = (days, include_weekend) => {
        var date = moment();
        while (days > 0) {
            date = date.add(1, 'days');
            if (include_weekend) { // decrease "days" only if it's a weekday.
                days -= 1;
            } else {
                if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                    days -= 1;
                }
            }
        }
        return date;
    }

    const handleDateChange = (date, dateString, fieldName) => {
        let dateValues = {}
        if (fieldName === 'start_at' && cityDetails.lead_time) {
            const includeWeekend = (cityDetails?.counting_system === 'calendar_days') ? true : false;
            const cityExpeditedDate = addWeekdays(cityDetails.lead_time, includeWeekend);
            const expeditedDate = cityExpeditedDate.format('MM/DD/YYYY');
            if (moment(dateString) > moment(expeditedDate)) {
                setIsRush(false)
            } else {
                setIsRushModalOpen(true)
                setIsRush(true)
            }
        }

        if (fieldName === 'start_at') {
            dateValues.start_at = dateString
            dateValues.end_at = ''
            setPermitValues({ ...permitValues, ...dateValues })

        } else if (fieldName === 'end_at') {
            if (permitValues.start_at) {
                let date1 = moment(dateString);
                let date2 = moment(permitValues.start_at);
                let diffDays = date1.diff(date2, 'days')
                console.log('diffDays =======', diffDays)
                setDiffDaysNumber(diffDays + 1)
                setPermitValues({ ...permitValues, [fieldName]: dateString })
            }
        } else {
            setPermitValues({ ...permitValues, [fieldName]: dateString })
        }
        if (fieldName === 'end_at') {
            if (cityDetails?.sign_removal || cityDetails?.site_review) {
                setIsSignModalOpen(true)
            }
        }
    }

    const handleSubmitData = async (e) => {
        e.preventDefault();
        let errorData = await getErrors(permitValues)
        console.log('errorData =====', errorData)
        if (JSON.stringify(errorData) === '{}') {
            setDisableSubmit(true)
            const values = JSON.parse(JSON.stringify(permitValues))
            let finalDatas = await processDatas(values, isRush, cityDetails)
            finalDatas.isLoggedIn = quickPermit ? true : isLoggedIn;
            if (permitDetails.permit_status === 'draft') {
                finalDatas.permit_status = permitDetails.permit_status;
            }
            await handleSubmit(finalDatas)
            setTimeout(() => {
                setDisableSubmit(false)
            }, 3000)

        } else {
            setErrList(errorData)
        }
    }

    return (
        <section className="common-content-wrapper common-dark-bg request-permit ng-scope">
            <div className="layout-container">
                {/*start page-title*/}
                <div className="page-title">
                    <h2 className="content-primary-title clear-margins">
                        Request a Permit
                    </h2>
                    <span className="content-sub-head">
                        Fill out the simple form below to get your permit started. We'll
                        contact you if we need more information.
                    </span>
                </div>
                {/*end page-title*/}
                {/*start page page-white*/}
                <div className="permit-steps">
                    {/*permit information content*/}
                    <div className="permit-content">
                        <form
                            // ng-submit="requestPermit.$valid && submitForm()"
                            className="basic-form ng-pristine ng-invalid ng-invalid-required ng-valid-pattern"
                            id="requestPermit"
                            name="requestPermit"
                            noValidate=""
                            autoComplete="off"
                        >
                            <input
                                style={{ display: "none" }}
                                type="text"
                                name="street_address"
                                value={permitValues.street_address || ''}
                            />
                            <input style={{ display: "none" }} type="text" name="apartment" value={permitValues.apartment || ''} />
                            <div className="request-section-heading">PERMIT INFORMATION</div>
                            <div className="form-heading">
                                <span>Where</span> do you need the permit?
                            </div>
                            <div className="layout-wrapper medium-grid">
                                <div className="layout-sm-5">
                                    <div className="form-group">
                                        <label>
                                            City <span className="color">*</span>
                                            <span className="tooltip tooltip-top sm-width align-left-xs">
                                                <i className="info-tooltip mpIcons-help" />
                                                <span className="tooltip-content">
                                                    Choose a city and we'll explain how the permit works.
                                                </span>
                                            </span>
                                        </label>
                                        <select
                                            name="city_id"
                                            id="city"
                                            onChange={handleChange}
                                            className="default-select-box ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                            required=""
                                            value={permitValues.city_id || ''}
                                        >
                                            <option
                                                value=""
                                                disabled=""
                                                className=""
                                            // selected="selected"
                                            >
                                                Choose from our cities
                                            </option>
                                            {cities.map(city =>
                                                <option key={city?.city_id} label={`${city?.city_name}, ${city?.State?.state_code}`} value={city?.city_id}>
                                                    {`${city?.city_name}, ${city?.State?.state_code}`}
                                                </option>
                                            )}
                                        </select>
                                        <span className={`form-error ${!errList.city_id ? 'ng-hide' : ''}`}>
                                            Select a city
                                        </span>
                                        {permitValues.city_id && cityDetails ?
                                            <button
                                                type="button"
                                                className="btn secondary-btn xs-rounded-btn view-city-hints-btn ng-binding "
                                                onClick={() => setIsCityPopupOpen(true)}
                                            >
                                                <span />
                                                View {cityDetails.city_name} Pricing &amp; Details
                                            </button> : null}
                                        <span className="arrow-splash" ng-show="splashAlert && permit.userDetails.permit_booked == 0" ></span>
                                    </div>
                                </div>
                                <div className="layout-sm-7">
                                    <div className="form-group">
                                        <label htmlFor="address">
                                            Street Address <span className="color">*</span>
                                        </label>
                                        <input
                                            id="address"
                                            className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                            type="text"
                                            name="street_address"
                                            placeholder="Enter street address"
                                            required=""
                                            value={permitValues.street_address || ''}
                                            onChange={handleChange}
                                        />
                                        <span
                                            className={`form-error ${!errList.street_address ? 'ng-hide' : ''}`}
                                            ng-show="(requestPermit.street_address.$invalid && requestPermit.$submitted)"
                                        >
                                            This field is required
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="layout-wrapper medium-grid">
                                <div className="layout-sm-4 layout-md-3">
                                    <div className="form-group">
                                        <label htmlFor="Apartment">Apartment#</label>
                                        <input
                                            id="Apartment"
                                            name="apartment"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                            onChange={handleChange}
                                            value={permitValues.apartment || ''}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="layout-sm-4 layout-md-3 ng-hide"
                                    ng-show="permit.source == 'CP'"
                                >
                                    <div className="form-group">
                                        <label htmlFor="additional_location_info">
                                            Additional location info#
                                        </label>
                                        <input
                                            id="additional_location_info"
                                            name="additional_location_info"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="layout-sm-5 layout-md-3">
                                    <div className="form-group mb-2x">
                                        <label htmlFor="zip">
                                            Postal Code <span className="color">*</span>
                                        </label>
                                        <input
                                            id="zip"
                                            placeholder="Enter postal code"
                                            name="zipcode"
                                            className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern"
                                            type="text"
                                            ng-pattern="/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *(\d[A-Z]\d)*)$/"
                                            required=""
                                            onChange={handleChange}
                                            value={permitValues.zipcode || ''}
                                        />
                                        <span
                                            className={`form-error ${!errList.zipcode ? 'ng-hide' : ''}`}
                                            ng-show="(requestPermit.zipcode.$error.required  && requestPermit.$submitted)"
                                        >
                                            This field is required
                                        </span>
                                        <span
                                            className={`form-error ${!errList.invalidZip ? 'ng-hide' : ''}`}
                                            ng-show="(requestPermit.zipcode.$error.pattern && requestPermit.$submitted)"
                                        >
                                            Invalid postal code
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-heading">
                                <span>When</span> do you need the permit?
                            </div>
                            <div className="layout-wrapper medium-grid">
                                <div className="layout-sm-3 layout-md-2">
                                    <div className="form-group">
                                        <label>
                                            Start Date <span className="color">*</span>
                                        </label>
                                        {type === 'update' && permitValues.start_at ?
                                            <DatePicker
                                                allowClear={false}
                                                format='MM-DD-YYYY'
                                                disabledDate={(current) => moment().add(-1, 'days') >= current}
                                                onChange={(date, dateString) => handleDateChange(date, dateString, 'start_at')}
                                                defaultValue={permitValues.start_at ? dayjs(permitValues.start_at, 'MM-DD-YYYY') : ''}
                                            /> : null}

                                        {type === 'create' ?
                                            <DatePicker
                                                allowClear={false}
                                                format='MM-DD-YYYY'
                                                disabledDate={(current) => moment().add(-1, 'days') >= current}
                                                onChange={(date, dateString) => handleDateChange(date, dateString, 'start_at')}
                                                defaultValue={permitValues.start_at ? dayjs(permitValues.start_at, 'MM-DD-YYYY') : ''}
                                                value={permitValues.start_at ? dayjs(permitValues.start_at, 'MM-DD-YYYY') : ''}
                                            /> : null}
                                        <span
                                            className={`form-error ${!errList.start_at ? 'ng-hide' : ''}`}
                                            ng-show="(permit.startDatevalid == true && requestPermit.$submitted) || startDateError"
                                        >
                                            This field is required
                                        </span>
                                    </div>
                                    <div className="field-hint permit-days">
                                        {isRush ?
                                            <span
                                                ng-show="showExpeditedServiceWarning"
                                                className="ng-binding"
                                            >
                                                <span className="mpIcons-running rush-booking" />
                                                Additional rush order fee of ${cityDetails?.expedited_service_charge || 30} may be included in your invoice

                                                <span className="tooltip tooltip-top sm-width multiple-line">
                                                    <i className="info-tooltip mpIcons-help" />
                                                    <span className="tooltip-content">
                                                        Yikes, we'll need to move quick! Yonkers prefers a lead time of {cityDetails?.lead_time || 3} business days. If the city approves a permit for your request, an expedite fee of ${cityDetails?.expedited_service_charge || 30} will be added to your invoice.
                                                    </span>
                                                </span>
                                            </span>
                                            : null}
                                        {diffDaysNumber ?
                                            <span className="jsPermitDays hint-from-sm" >
                                                This is a {diffDaysNumber}-day permit request
                                            </span>
                                            : null}
                                    </div>
                                </div>
                                <div className="layout-sm-3 layout-md-2">
                                    <div className="form-group mb-3x">
                                        <label>
                                            End Date <span className="color">*</span>
                                        </label>
                                        {type === 'update' && (permitValues.end_at || permitValues.start_at) ?

                                            <DatePicker
                                                allowClear={false}
                                                format='MM-DD-YYYY'
                                                disabledDate={(current) => !current || current.isBefore(permitValues?.start_at) || permitValues?.start_at < moment().endOf('day')}
                                                onChange={(date, dateString) => handleDateChange(date, dateString, 'end_at')}
                                                defaultValue={permitValues.end_at ? dayjs(permitValues.end_at, 'MM-DD-YYYY') : ''}
                                                value={permitValues.end_at ? dayjs(permitValues.end_at, 'MM-DD-YYYY') : ''}
                                            // defaultValue={permitValues.end_at ? moment(permitValues.end_at, 'YYYY-MM-DD') : ''}
                                            /> : null}

                                        {type === 'create' ?

                                            <DatePicker
                                                allowClear={false}
                                                format='MM-DD-YYYY'
                                                disabledDate={(current) => !current || current.isBefore(permitValues?.start_at) || permitValues?.start_at < moment().endOf('day')}
                                                onChange={(date, dateString) => handleDateChange(date, dateString, 'end_at')}
                                                value={permitValues.end_at ? dayjs(permitValues.end_at, 'MM-DD-YYYY') : ''}
                                            /> : null}
                                        <span
                                            className={`form-error ${!errList.end_at ? 'ng-hide' : ''}`}
                                            ng-show="(permit.endDatevalid == true && requestPermit.$submitted) || endDateError"
                                        >
                                            This field is required
                                        </span>
                                        <span
                                            className={`form-error ${!errList.invalidDate ? 'ng-hide' : ''}`}
                                            ng-show="(permit.endDatevalid == true && requestPermit.$submitted) || endDateError"
                                        >
                                            End date should be greater than start date
                                        </span>
                                    </div>
                                    <div className="field-hint permit-days b-28 hint-only-xs">
                                        <span className="jsPermitDays" />
                                    </div>
                                </div>
                                <div
                                    className="layout-sm-6 layout-md-8 "
                                    ng-show="permit.showsignRemoval || permit.showSiteReview"
                                >
                                    <div className="wrap-sign-site-cb-block">
                                        {cityDetails?.sign_removal ?
                                            <div
                                                className="item-block-sign-site "
                                                ng-show="permit.showsignRemoval"
                                            >
                                                <label
                                                    htmlFor="signremoval"
                                                    className="checkbox tooltip sm-width tooltip-bottom  multiple-line align-left-xs"
                                                >
                                                    <input
                                                        ng-disabled="disableSignRemoveBtn"
                                                        type="checkbox"
                                                        id="signremoval"
                                                        name="sign_removal"
                                                        onChange={handleCheckBoxChange}
                                                        className="ng-pristine ng-untouched ng-valid ng-empty"
                                                        checked={permitValues.sign_removal}
                                                    />
                                                    <span>Sign removal</span>
                                                    {/* ngIf: disableSignRemoveBtn */}
                                                </label>
                                                <span onClick={() => setIsSignModalOpen(true)} className="tooltip tooltip-bottom single-line align-left-xs">
                                                    <i
                                                        className="info-tooltip mpIcons-help"
                                                    />
                                                    <span className="tooltip-content">
                                                        Click here to view details
                                                    </span>
                                                </span>
                                            </div> : null}
                                        {cityDetails?.site_review ?
                                            <div
                                                className="item-block-sign-site "
                                                ng-show="permit.showSiteReview"
                                            >
                                                <label htmlFor="sitereview" className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="sitereview"
                                                        name="site_review"
                                                        className="ng-pristine ng-untouched ng-valid ng-empty"
                                                        onChange={handleCheckBoxChange}
                                                        checked={permitValues.site_review}
                                                    />
                                                    <span>Site review</span>
                                                </label>
                                                <span onClick={() => setIsSignModalOpen(true)} className="tooltip tooltip-bottom single-line align-left-xs">
                                                    <i
                                                        className="info-tooltip mpIcons-help"
                                                    />
                                                    <span className="tooltip-content">
                                                        Click here to view details
                                                    </span>
                                                </span>
                                            </div> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="form-heading">
                                <span>Why</span> do you need the permit?
                            </div>
                            <div className="layout-wrapper medium-grid">
                                <div className="layout-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="Permitreason">
                                            Permit reason <span className="color">*</span>
                                        </label>
                                        <select
                                            name="reason_id"
                                            id="permitReason"
                                            className="default-select-box ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                                            ng-options="eachReason.reason_id as eachReason.reason for eachReason in permit.reasons"
                                            required=""
                                            style={{}}
                                            // defaultValue={permitValues.reason_id}
                                            onChange={handleChange}
                                            value={permitValues.reason_id || ''}
                                        >
                                            {/* <option value="" disabled="" className="">
                                                Permit reason
                                            </option> */}
                                            {reasons.map(reason =>
                                                <option key={reason.reason_id} label={reason.reason} value={reason.reason_id}>
                                                    {reason.reason}
                                                </option>
                                            )}
                                        </select>
                                        <span
                                            className={`form-error ${!errList.reason_id ? 'ng-hide' : ''}`}
                                            ng-show="(requestPermit.reason.$error.required && requestPermit.$submitted )"
                                        >
                                            Select a reason
                                        </span>
                                    </div>
                                </div>
                                <div className="layout-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="MeterNumbers">Meter Numbers</label>
                                        <input
                                            name="meter_numbers"
                                            id="MeterNumbers"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                            onChange={handleChange}
                                            value={permitValues.meter_numbers || ''}
                                        />
                                    </div>
                                </div>
                                <div className="layout-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="shipperName">
                                            Shipper name/PO#
                                            <span className="tooltip tooltip-top">
                                                <i className="info-tooltip mpIcons-help" />
                                                <span className="tooltip-content single-line">
                                                    companies only
                                                </span>
                                            </span>
                                        </label>
                                        <input
                                            name='shipper_name'
                                            id="shipperName"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                            ng-focus="permit.shippervalid=false"
                                            onChange={handleChange}
                                            value={permitValues.shipper_name || ''}
                                        />
                                        <span
                                            className="form-error ng-hide"
                                            ng-show="(permit.shippervalid == true && requestPermit.$submitted)"
                                        >
                                            This field is required
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="layout-sm-4 ng-hide"
                                    ng-show="permit.source == 'CP'"
                                >
                                    <div className="form-group">
                                        <label htmlFor="container_no">No of Containers</label>
                                        <input
                                            id="container_no"
                                            name="container_no"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern"
                                            type="text"
                                            ng-pattern="/^\d+$/"
                                        />
                                        <span
                                            className="form-error ng-hide"
                                            ng-show="requestPermit.container_no.$dirty && requestPermit.container_no.$error.pattern"
                                        >
                                            Invalid data
                                        </span>{" "}
                                    </div>
                                </div>
                                <div
                                    className="layout-sm-4 ng-hide"
                                    ng-show="permit.source == 'CP'"
                                >
                                    <div className="form-group">
                                        <label htmlFor="provider">Provider</label>
                                        <input
                                            id="provider"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="layout-sm-12 layout-md-4">
                                    <div className="form-group">
                                        <label htmlFor="specialRequirement">
                                            Special Requirements <span>(optional)</span>
                                            <span className="tooltip tooltip-top sm-width align-right-xs">
                                                <i className="info-tooltip mpIcons-help" />
                                                <span className="tooltip-content">
                                                    Let us know exactly what you need (footage, special
                                                    location, special times, etc). Please be as concise as
                                                    possible.
                                                </span>
                                            </span>
                                        </label>
                                        <textarea
                                            name='special_requirements'
                                            id="specialRequirement"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            rows={3}
                                            onChange={handleChange}
                                            value={permitValues.special_requirements || ''}
                                        />
                                    </div>
                                </div>
                                <div className="layout-sm-4 layout-md-7">
                                    <div className="form-group">
                                        <button
                                            mixular="click"
                                            eventlabel="Clicked Order my Permit button from request permit page"
                                            className="btn secondary-btn submit-btn xs-rounded-btn is-loader ng-binding submit-button"
                                            onClick={handleSubmitData}
                                            disabled={disableSubmit}
                                        >
                                            {submitButtonText}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/*end page page-white*/}
            </div>
            <RushHourPopup isRushModalOpen={isRushModalOpen} handleOk={() => setIsRushModalOpen(false)} cityDetails={cityDetails} />
            <CityDetailsPopup isCityPopupOpen={isCityPopupOpen} handleOk={() => setIsCityPopupOpen(false)} cityDetails={cityDetails} />
            <SignReviewPopup isSignModalOpen={isSignModalOpen} handleCancel={() => setIsSignModalOpen(false)} cityDetails={cityDetails} onSiteSubmit={(data) => handleSiteSignChange(data)} permitValues={permitValues} />

        </section>



    )
}

export default PermitInformationForm