
export const processUserDetails = (userDetails) => {
    let permitValues = { reason_id: '52', sign_removal: false, site_review: false }
    if (userDetails?.UserOption?.first_name) {
        permitValues.first_name = userDetails.UserOption.first_name;
    }
    if (userDetails?.UserOption?.last_name) {
        permitValues.last_name = userDetails.UserOption.last_name;
    }
    if (userDetails?.UserOption?.mailing_address) {
        permitValues.billing_address = userDetails.UserOption.mailing_address;
    }
    if (userDetails?.UserOption?.mailing_city) {
        permitValues.billing_city = userDetails.UserOption.mailing_city;
    }
    if (userDetails?.UserOption?.mailing_state) {
        permitValues.state_id = userDetails.UserOption.mailing_state;
    }
    if (userDetails?.UserOption?.mailing_zip) {
        permitValues.billing_zipcode = userDetails.UserOption.mailing_zip;
    }
    if (userDetails?.UserOption?.phone) {
        permitValues.billing_phone = userDetails.UserOption.phone;
    }
    if (userDetails?.UserOption?.phone_ext) {
        permitValues.billing_phone_ext = userDetails.UserOption.phone_ext;
    }
    if (userDetails?.UserOption?.fax) {
        permitValues.billing_fax = userDetails.UserOption.fax;
    }
    if (userDetails?.UserOption?.company_name) {
        permitValues.company_name = userDetails.UserOption.company_name;
    }
    if (userDetails?.UserOption?.shipper_name) {
        permitValues.shipper_name = userDetails.UserOption.shipper_name;
    }
    if (userDetails?.user_id) {
        permitValues.user_id = userDetails.user_id;
    }
    if (userDetails?.UserOption?.authorize_customer_id) {
        permitValues.authorize_customer_id = userDetails.UserOption.authorize_customer_id;
    }
    return permitValues
}
