import axios from "axios";
import queryString from 'query-string';
import { BASE_URL } from "./actions/types";

export const apiInstance = axios.create({
  baseURL: BASE_URL,
});

export const getHeaders = function () {
  let headerData = {
    "Content-type": "application/json"
  };
  const parsed = queryString.parse(window.location.search, { ignoreQueryPrefix: true });
  let token = parsed.token;
  if (!parsed.token) {
    const authDetails = JSON.parse(localStorage.getItem('authDetails'));
    token = authDetails && authDetails.token;
  }
  if (token) {
    headerData["x-access-token"] = token;
  }
  return headerData;
};

// Axios interceptor to update headers before each request
apiInstance.interceptors.request.use(config => {
  config.headers = getHeaders();
  return config;
});

export default apiInstance;


// export const getHeaders = function () {
//   console.log('1111111111111111111111111111111111111111111111')
//   let headerData = {
//     "Content-type": "application/json"
//   }
//   const parsed = queryString.parse(window.location.search, { ignoreQueryPrefix: true });
//   let token = parsed.token
//   console.log('parsed ========', parsed)
//   console.log('token ========', token)
//   if (!parsed.token) {
//     console.log('NOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO ========', token)
//     const authDetails = JSON.parse(localStorage.getItem('authDetails'));
//     token = authDetails && authDetails.token
//   }
//   headerData["x-access-token"] = token
//   console.log('headerData =============', headerData)
//   return headerData
// };

// export default axios.create({
//   baseURL: BASE_URL,
//   headers: getHeaders()
// });

