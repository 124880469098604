import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import './permitAccountDetails.css'
import {
    getMe,
    submitPermit,
    authLogin,
    authSignUp,
    createPermitStepOne,
    setPermitInformation,
    createPermitMidStep,
} from "../../../actions/permits";

import { checkMeDetails } from '../../../helpers/utils'
import PermitInAccountDetailsForm from '../../../components/permitInAccountDetailsForm/permitInAccountDetailsForm'
import PermitInCustomerInformation from '../../../components/permitInCustomerInformation/permitInCustomerInformation'
import SignUpForm from '../../../components/signUpForm/signUpForm'
import { handleRedirectPermits } from "../../../helpers/utils";

import Header from '../../../components/header/header'
import { Spin, notification } from 'antd';
import { TOASTER } from "../../../actions/types";

const PermitAccountDetails = ({ isLoggedIn, angularUrl }) => {
    const history = useHistory();
    const [api, contextHolder] = notification.useNotification();
    const [isGuest, setIsGuest] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [loader, setLoader] = useState(true);

    const openNotificationWithIcon = (type, placement, title, description) => {
        api[type]({
            message: title,
            description,
            placement
        });
    };
    const { cities, states, me, reasons, cardDetails, cityDetails, permitDetails, permitInformation } = useSelector(state => state.permits);
    const dispatch = useDispatch();

    const init = async () => {
        document.title = 'MovingPermits.com - Request permit';
        if (!permitInformation?.city_id) {
            history.push(`/permits/permit-information`);
        }
        if (isLoggedIn) {
            try {
                const meData = await dispatch(getMe());
                const checkMeFields = checkMeDetails(meData?.data);
                await dispatch(setPermitInformation({ ...permitInformation, isLoggedIn: true }))
                const stepOneResponse = await dispatch(createPermitStepOne({ ...permitInformation, isLoggedIn: true }))

                if (!checkMeFields) {
                    setCurrentTab(1);
                    await setLoader(false)
                } else {
                    let customerData = {
                        first_name: meData?.data?.UserOption?.first_name,
                        last_name: meData?.data?.UserOption?.last_name,
                        billing_address: meData?.data?.UserOption?.mailing_address,
                        billing_city: meData?.data?.UserOption?.mailing_city,
                        billing_phone_ext: meData?.data?.UserOption?.phone_ext,
                        billing_fax: meData?.data?.UserOption?.fax,
                        billing_zipcode: meData?.data?.UserOption?.mailing_zip,
                        state_id: meData?.data?.UserOption?.mailing_state,
                        billing_phone: meData?.data?.UserOption?.phone,
                        company_name: meData?.data?.UserOption?.company_name,
                    }
                    const permitObj = { ...permitInformation, ...customerData, isLoggedIn: true, permit_id: stepOneResponse.data.permit_id }
                    await dispatch(setPermitInformation(permitObj))
                    const permitMidSection = await dispatch(createPermitMidStep(permitObj))
                    await setLoader(false)
                    if (permitMidSection?.data?.permit_id) {
                        await history.push(`/permits/payment-information`);
                    }
                    // history.push(`/permits/payment-information`);
                }
            } catch (error) {
                // Handle error
                console.error('Error fetching data:', error);
            }
        }
        await setLoader(false)
    };

    useEffect(() => {
        init()
    }, [dispatch]);

    const afterAuthentication = async (responseData) => {
        await localStorage.setItem('authDetails', JSON.stringify(responseData));
        openNotificationWithIcon('success', 'bottomRight', TOASTER.SUCCESS_TITLE, TOASTER.LOGIN_SUCCESS_MESSAGE)
        const meData = await dispatch(getMe());
        await dispatch(setPermitInformation({ ...permitInformation, isLoggedIn: true }))
        await dispatch(createPermitStepOne({ ...permitInformation, isLoggedIn: true }))
        const checkMeFields = checkMeDetails(meData?.data)
        if (!checkMeFields) {
            setCurrentTab(1)
        } else {
            await history.push(`/permits/payment-information`);
        }
    }

    const handleLoginSubmit = async (data) => {
        const loginResponse = await dispatch(authLogin(data))
        if (loginResponse?.data?.failed) {
            openNotificationWithIcon('error', 'bottomRight', TOASTER.ERROR_TITLE, loginResponse?.data.message || TOASTER.LOGIN_ERROR_MESSAGE)
        }
        if (loginResponse?.data?.token) {
            afterAuthentication(loginResponse?.data)
        }
    }

    const handleSignUpSubmit = async (data) => {
        const signUpResponse = await dispatch(authSignUp(data))
        if (signUpResponse?.data?.failed) {
            openNotificationWithIcon('error', 'bottomRight', TOASTER.ERROR_TITLE, signUpResponse?.data.message || TOASTER.LOGIN_ERROR_MESSAGE)
        }
        if (signUpResponse?.data?.token) {
            afterAuthentication(signUpResponse?.data)
        }
    }

    const handleCustomerCheckboxSubmit = (data) => {
        if (data === "GUEST") {
            setIsGuest(true)
            setCurrentTab(1)
        }
        if (data === "REGISTER") {
            setCurrentTab(2)
        }
    }

    const handleCustomerSubmit = async (data) => {
        if (isGuest) {
            const stepOneResponse = await dispatch(createPermitStepOne({ ...permitInformation, ...data, isLoggedIn: false }))
            if (stepOneResponse?.data?.failed) {
                openNotificationWithIcon('error', 'bottomRight', TOASTER.ERROR_TITLE, stepOneResponse?.data.message || TOASTER.ERROR_MESSAGE)
            } else {
                const permitObj = { ...permitInformation, ...data, isLoggedIn: false, permit_id: stepOneResponse.data.permit_id }
                await dispatch(setPermitInformation(permitObj))
                const permitMidSection = await dispatch(createPermitMidStep(permitObj))
                await setLoader(false)
                if (permitMidSection?.data?.permit_id) {
                    await history.push(`/permits/payment-information`);
                }
            }
        } else {
            await dispatch(setPermitInformation({ ...permitInformation, ...data, isLoggedIn: true }))
            const permitMidSection = await dispatch(createPermitMidStep({ ...permitInformation, ...data, isLoggedIn: true }))
            // if (signUpResponse?.data?.failed) {
            //     openNotificationWithIcon('error', 'bottomRight', TOASTER.ERROR_TITLE, signUpResponse?.data.message || TOASTER.LOGIN_ERROR_MESSAGE)
            // }
            if (permitMidSection?.data?.permit_id) {
                await history.push(`/permits/payment-information`);
            }
        }
    }

    return (
        <>
            {contextHolder}
            {!loader ?
                <>
                    <Header me={me} angularUrl={angularUrl} />
                    <div className="main-layout ng-scope" ui-view="" style={{}}>
                        <section className="breadcrumbs ng-scope">
                            <div className="layout-container">
                                <ul>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => handleRedirectPermits('/')}>
                                            Home
                                        </span>
                                    </li>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => handleRedirectPermits()}>
                                            Permits
                                        </span>
                                    </li>
                                    <li>Request a permit</li>
                                </ul>
                            </div>
                        </section>
                        {currentTab === 0 ?
                            <PermitInAccountDetailsForm
                                angularUrl={angularUrl}
                                isLoggedIn={isLoggedIn}
                                cities={cities ? cities.rows : []}
                                reasons={reasons ? reasons.rows : []}
                                me={me}
                                states={states ? states.rows : []}
                                handleSubmit={(data) => handleCustomerCheckboxSubmit(data)}
                                handleLoginSubmit={handleLoginSubmit}
                                customerProfileId={cardDetails?.profile?.customerProfileId}
                                cardDetails={cardDetails?.profile?.paymentProfiles}
                                cityDetails={cityDetails?.data}
                                submitButtonText='Continue'
                                permitDetails={permitDetails && permitDetails.data ? permitDetails.data : {}}
                                type='create'
                            /> : null}
                        {currentTab === 1 ?
                            <PermitInCustomerInformation
                                angularUrl={angularUrl}
                                isLoggedIn={isLoggedIn}
                                cities={cities ? cities.rows : []}
                                reasons={reasons ? reasons.rows : []}
                                me={me}
                                states={states ? states.rows : []}
                                handleSubmit={(data) => handleCustomerSubmit(data)}
                                customerProfileId={cardDetails?.profile?.customerProfileId}
                                cardDetails={cardDetails?.profile?.paymentProfiles}
                                cityDetails={cityDetails?.data}
                                submitButtonText='Continue'
                                permitDetails={permitDetails && permitDetails.data ? permitDetails.data : {}}
                                type='create'
                                isGuest={isGuest}
                            /> : null}
                        {currentTab === 2 ?
                            <SignUpForm
                                angularUrl={angularUrl}
                                isLoggedIn={isLoggedIn}
                                cities={cities ? cities.rows : []}
                                reasons={reasons ? reasons.rows : []}
                                me={me}
                                states={states ? states.rows : []}
                                handleSubmit={(data) => dispatch(submitPermit(data))}
                                handleSignUpSubmit={handleSignUpSubmit}
                                customerProfileId={cardDetails?.profile?.customerProfileId}
                                cardDetails={cardDetails?.profile?.paymentProfiles}
                                cityDetails={cityDetails?.data}
                                submitButtonText='Continue'
                                permitDetails={permitDetails && permitDetails.data ? permitDetails.data : {}}
                                type='create'
                                handleBackButton={() => setCurrentTab(0)}
                            /> : null}
                    </div>
                </>
                :
                <Spin className='loader' size="large" />
            }
        </>
    )
}

export default PermitAccountDetails