import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import './quickPermits.css'
import {
    getSettingList,
    getStates,
    getClassification,
    getCities,
    getMe,
    getReasons,
    submitPermit,
    getCardDetails,
    getUserDetails,
} from "../../actions/permits";
import PermitForm from '../../components/permitForm/permitsForm'
import { processUserDetails } from './quickPermitsUtils'
import Header from '../../components/header/header'
import { Spin, notification } from 'antd';
import { TOASTER } from "../../actions/types";

const QuickPermits = () => {
    const history = useHistory();
    const [api, contextHolder] = notification.useNotification();
    const [permitDetails, setPermitDetails] = useState({});
    
    const openNotificationWithIcon = (type, placement, title, description) => {
        api[type]({
            message: title,
            description,
            placement
        });
    };

    const { cities, states, me, reasons, cardDetails, cityDetails, permitSubmit, setting, userDetails } = useSelector(state => state.permits);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSettingList({ setting_key: "angular_url" }));
        dispatch(getStates());
        dispatch(getCities());
        dispatch(getClassification());
        dispatch(getMe());
        dispatch(getReasons());

        const authDetails = JSON.parse(localStorage.getItem('authDetails'));
        if (authDetails.user_id) {
            dispatch(getUserDetails(authDetails.user_id));
            dispatch(getCardDetails(authDetails.user_id));
        }
        document.title = 'MovingPermits.com - Request permit';
    }, [dispatch]);


    useEffect(() => {
        console.log('userDetails ======', userDetails)
        async function fetchData() {
            const response = await processUserDetails(userDetails.data)
            console.log('response ======', response)
            setPermitDetails(response)
        }
        if (userDetails?.data?.user_id) {
            fetchData();
        }
    }, [userDetails]);


    useEffect(() => {
        console.log('CCCCCCCCC', window.location.pathname)
        if (setting && setting.option_value) {
            if (window.location.pathname !== '/permits/create' && window.location.pathname !== '/permits/update' && window.location.pathname !== '/quickpermits/create') {
                window.location.replace(`${setting.option_value}/login`);
            }
        }
    }, [setting]);

    useEffect(() => {
        console.log('permitSubmit ==========', permitSubmit)
        if (permitSubmit?.permit_id) {
            openNotificationWithIcon('success', 'bottomRight', TOASTER.SUCCESS_TITLE, TOASTER.SUCCESS_MESSAGE)
            setTimeout(() => {
                window.location.replace(`${me.angular_url}/order/${permitSubmit?.permit_id}`);
            }, 1000)
        }
        if (permitSubmit?.failed) {
            openNotificationWithIcon('error', 'bottomRight', TOASTER.ERROR_TITLE, permitSubmit.message || TOASTER.ERROR_MESSAGE)
        }
    }, [permitSubmit]);

    console.log('settings.JS ======================', setting)
    console.log('cities, states, me, reasons.JS ======================', cities, states, reasons, history)

    return (
        <>
            {contextHolder}
            {JSON.stringify(me) !== '{}' ?
                <>
                    <Header me={me} setting={setting} old={true} />
                    <div className="main-layout ng-scope" ui-view="" style={{}}>
                        <section className="breadcrumbs ng-scope">
                            <div className="layout-container">
                                <ul>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => window.location.replace(`${me.angular_url}/`)}>
                                            Home
                                        </span>
                                    </li>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => window.location.replace(`${me.angular_url}/permits`)}>
                                            Permits
                                        </span>
                                    </li>
                                    <li>Request a permit</li>
                                </ul>
                            </div>
                        </section>
                        <PermitForm
                            isLoggedIn={true}
                            cities={cities ? cities.rows : []}
                            reasons={reasons ? reasons.rows : []}
                            me={me}
                            states={states ? states.rows : []}
                            handleSubmit={(data) => dispatch(submitPermit(data))}
                            customerProfileId={cardDetails?.profile?.customerProfileId}
                            cardDetails={cardDetails?.profile?.paymentProfiles}
                            cityDetails={cityDetails?.data}
                            submitButtonText='Order my Permit'
                            permitDetails={permitDetails || {}}
                            type='create'
                            quickPermit={true}
                        />
                    </div>
                </>
                :
                <Spin className='loader' size="large" />
            }
        </>
    )
}

export default QuickPermits