import moment from 'moment';


export const getErrors = (permitValues, isSavedCards, isLoggedIn, quickPermit) => {
    let errs = {}
    console.log('permitValues.paymentMethod ========', permitValues.paymentMethod)
    if (!permitValues.reason_id) {
        errs.reason_id = true
    }
    if (!permitValues.first_name) {
        errs.first_name = true
    }
    if (!permitValues.last_name) {
        errs.last_name = true
    }
    if (!permitValues.billing_address) {
        errs.billing_address = true
    }
    if (!permitValues.billing_city) {
        errs.billing_city = true
    }
    if (!permitValues.billing_zipcode) {
        errs.billing_zipcode = true
    }
    if (!permitValues.state_id) {
        errs.state_id = true
    }
    if (!permitValues.billing_phone) {
        errs.billing_phone = true
    }
    if (!permitValues.city_id) {
        errs.city_id = true
    }
    if (!permitValues.street_address) {
        errs.street_address = true
    }
    if (!permitValues.zipcode) {
        errs.zipcode = true
    }
    if (!permitValues.start_at) {
        errs.start_at = true
    }
    if (!permitValues.end_at) {
        errs.end_at = true
    }
    if (!quickPermit && !isLoggedIn && !permitValues.email) {
        errs.email = true
    }
    if (!permitValues.paymentMethod) {
        if (!permitValues.payment_method_token) {
            if (!permitValues.card_number) {
                errs.card_number = true
            }
            if (!permitValues.card_expiration_date) {
                errs.card_expiration_date = true
            }
            if (!permitValues.card_security_code) {
                errs.card_security_code = true
            }
        }
    }
    if (!permitValues.termsandservice) {
        errs.termsandservice = true
    }
    if (!permitValues.termsandservice) {
        errs.termsandservice = true
    }
    if (permitValues.zipcode) {
        if (permitValues.zipcode.length > 5 || permitValues.zipcode.match("^[0-9]*$") === null) {
            errs.invalidZip = true
        }
    }
    if (permitValues.billing_zipcode) {
        if (permitValues.billing_zipcode.length > 5 || permitValues.billing_zipcode.match("^[0-9]*$") === null) {
            errs.invalidBillingZip = true
        }
    }

    if (!errs.start_at && !errs.end_at) {
        console.log('111111111111111111111111111', moment(permitValues.end_at).diff(moment(permitValues.start_at), 'days'))
        if (moment(permitValues.end_at).diff(moment(permitValues.start_at)) < 0) {
            errs.invalidDate = true
        }
    }

    return errs
}


export const processDatas = (permitValues, isRush, cityDetails) => {
    if (permitValues.card_number) {
        permitValues.card_number = permitValues.card_number.replace(/-/g, "");
    }
    if (permitValues.card_security_code) {
        permitValues.card_security_code = permitValues.card_security_code.replace(/\//g, "");
    }
    if (permitValues.card_expiration_date) {
        permitValues.card_expiration_date = permitValues.card_expiration_date.replace(/ /g, "");
    }
    if (permitValues.end_at) {
        permitValues.end_at = moment(permitValues.end_at, 'MM-DD-YYYY').format('YYYY-MM-DD');
    }
    if (permitValues.start_at) {
        permitValues.start_at = moment(permitValues.start_at, 'MM-DD-YYYY').format('YYYY-MM-DD');
    }
    if(isRush && cityDetails) {
        permitValues.counting_system = cityDetails.counting_system;
        permitValues.expedited_service_charge = cityDetails.expedited_service_charge;
        permitValues.lead_time = cityDetails.lead_time;
    }
    permitValues.payment_type = permitValues.paymentMethod ? '' : 'card'
    permitValues.source_site = 'react'
    return permitValues
}
