import {
  GET_SETTINGS,
  GET_STATES,
  GET_CITIES,
  GET_CLASSIFICATION,
  GET_ME,
  GET_REASON,
  SUBMIT_PERMIT,
  GET_CARD_DETAILS,
  GET_CITY_DETAILS,
  GET_PERMIT_DETAILS,
  UPDATE_PERMIT,
  GET_USER_DETAILS,
  PASSWORD_UPDATE,
  PERMIT_INFORMATION,
  LOGIN,
  PERMIT_STEPONE,
  SIGNUP,
} from "../actions/types";

import PermitService from "../services/permitService";

export const getSettingList = (data) => async (dispatch) => {
  try {
    const res = await PermitService.getSettingList(data);
    console.log('res ===========', res.data)
    dispatch({
      type: GET_SETTINGS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getStates = () => async (dispatch) => {
  try {
    const res = await PermitService.getStates();
    console.log('res ===========', res.data)
    dispatch({
      type: GET_STATES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getCities = () => async (dispatch) => {
  try {
    const res = await PermitService.getCities();

    dispatch({
      type: GET_CITIES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getClassification = () => async (dispatch) => {
  try {
    console.log('getClassification =========')
    const res = await PermitService.getAllClassification();

    dispatch({
      type: GET_CLASSIFICATION,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getMe = () => async (dispatch) => {
  try {
    console.log('getMe =========')
    const res = await PermitService.getMe();

    dispatch({
      type: GET_ME,
      payload: res.data,
    });
    return res.data
  } catch (err) {
    console.log(err);
  }
};

export const getReasons = () => async (dispatch) => {
  try {
    console.log('getReasons =========')
    const res = await PermitService.getReasons();

    dispatch({
      type: GET_REASON,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const submitPermit = (data) => async (dispatch) => {
  try {
    console.log('submitPermit =========')
    const res = await PermitService.submitPermit(data);

    dispatch({
      type: SUBMIT_PERMIT,
      payload: res.data,
    });
  } catch (err) {
    console.log('err ======', err);
    dispatch({
      type: SUBMIT_PERMIT,
      payload: { data: { failed: true, message: err?.message || err?.error || err || 'Failed to save Permit' } },
    });
  }
};


export const updatePermit = (id, data) => async (dispatch) => {
  try {
    console.log('updatePermit =========', id)
    const res = await PermitService.updatePermit(id, data);

    dispatch({
      type: UPDATE_PERMIT,
      payload: res.data,
    });
  } catch (err) {
    console.log('updatePermit err ======', err);
    dispatch({
      type: UPDATE_PERMIT,
      payload: { data: { failed: true, message: err?.message || err?.error || err || 'Failed to update Permit' } },
    });
  }
};

export const getCardDetails = (user_id, isGuest) => async (dispatch) => {
  try {
    console.log('getCardDetails =========', user_id)
    const res = await PermitService.getCardDetails(user_id, isGuest);

    dispatch({
      type: GET_CARD_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getCityDetails = (id) => async (dispatch) => {
  try {
    console.log('getCityDetails =========', id)
    const res = await PermitService.getCityDetails(id);

    dispatch({
      type: GET_CITY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getPermitDetails = (id) => async (dispatch) => {
  try {
    console.log('getPermitDetails =========', id)
    const res = await PermitService.getPermitDetails(id);

    dispatch({
      type: GET_PERMIT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getUserDetails = (id) => async (dispatch) => {
  try {
    console.log('getPermitDetails =========', id)
    const res = await PermitService.getUserDetails(id);

    dispatch({
      type: GET_USER_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const authChangePassword = (data) => async (dispatch) => {
  try {
    const res = await PermitService.authChangePassword(data);
    console.log('res ===========', res.data)
    dispatch({
      type: PASSWORD_UPDATE,
      payload: { data: { success: true, message: res.data } },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: PASSWORD_UPDATE,
      payload: { data: { failed: true, message: err?.message || err?.error || err || 'Failed to update password' } },
    });
  }
};

export const setPermitInformation = (data) => async (dispatch) => {
  try {
    console.log('submitPermit =========', data)
    // const res = await PermitService.submitPermit(data);
    dispatch({
      type: PERMIT_INFORMATION,
      payload: data,
    });
  } catch (err) {
    console.log('err ======', err);
    dispatch({
      type: SUBMIT_PERMIT,
      payload: { data: { failed: true, message: err?.message || err?.error || err || 'Failed to save Permit' } },
    });
  }
};

export const authLogin = (data) => async (dispatch) => {
  try {
    console.log('login =========')
    const res = await PermitService.login(data);

    dispatch({
      type: LOGIN,
      payload: res.data,
    });
    return res.data
  } catch (err) {
    console.log('err ======', err);
    dispatch({
      type: LOGIN,
      payload: { data: { failed: true, message: err?.message || err?.error || err || 'Invalid email or password' } },
    });
    return { data: { failed: true, message: err?.message || err?.error || err || 'Invalid email or password' } }
  }
};

export const authSignUp = (data) => async (dispatch) => {
  try {
    console.log('authSignUp =========', data)
    const res = await PermitService.signUp(data);
    dispatch({
      type: SIGNUP,
      payload: res.data,
    });
    return res.data
  } catch (err) {
    console.log('err ======', err);
    dispatch({
      type: SIGNUP,
      payload: { data: { failed: true, message: err?.message || err?.error || err || 'Invalid email or password' } },
    });
    return { data: { failed: true, message: err?.message || err?.error || err || 'Invalid email or password' } }
  }
};

export const createPermitStepOne = (data) => async (dispatch) => {
  try {
    console.log('login =========')
    const res = await PermitService.submitPermitStepOne(data);

    dispatch({
      type: PERMIT_STEPONE,
      payload: res.data,
    });
    return res.data
  } catch (err) {
    console.log('err ======', err);
    // dispatch({
    //   type: PERMIT_STEPONE,
    //   payload: { data: { failed: true, message: err?.message || err?.error || err || 'Failed to save Permit' } },
    // });
    return { data: { failed: true, message: err?.message || err?.error || err || 'Failed to save Permit' } }
  }
};

export const createPermitMidStep = (data) => async (dispatch) => {
  try {
    console.log('login =========')
    const res = await PermitService.submitPermitMidStep(data);
    dispatch({
      type: PERMIT_STEPONE,
      payload: res.data,
    });
    return res.data
  } catch (err) {
    console.log('err ======', err);
    dispatch({
      type: LOGIN,
      payload: { data: { failed: true, message: err?.message || err?.error || err || 'Failed to save Permit' } },
    });
  }
};

export const createPermitStepTwo = (data) => async (dispatch) => {
  try {
    console.log('login =========')
    const res = await PermitService.submitPermitStepTwo(data);
    dispatch({
      type: SUBMIT_PERMIT,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    console.log('err ======', err);
    dispatch({
      type: LOGIN,
      payload: { data: { failed: true, message: err?.message || err?.error || err || 'Failed to save Permit' } },
    });
    return { data: { failed: true, message: err?.message || err?.error || err || 'Failed to save Permit' } };
  }
};
