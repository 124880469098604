import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import "./print.css";
import "./screen.css";
import queryString from 'query-string';
import { history } from "./helpers/history";

import CreatePermits from "./containers/permits/createPermits/createPermits";
import QuickPermits from "./containers/quickPermits/quickPermits";
import UpdatePermits from "./containers/permits/updatePermits/updatePermits";
import OrderConfirm from "./containers/orderConfirm/orderConfirm";
import config from './config/config.json';

import PermitInformation from "./containers/newPermits/permitInformation/permitInformation";
import PermitCheckout from "./containers/newPermits/permitCheckout/permitCheckout";
import PermitAccountDetails from "./containers/newPermits/permitAccountDetails/permitAccountDetails";
import Logout from "./components/logout/logout";


let environment = process.env.REACT_APP_ENVIRONMENT || 'development';

// Higher Order Component to handle private routes
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem('authDetails') ?
      <Component {...props} angularUrl={config[environment].angularUrl} /> :
      // <Redirect to='/login' />
      <RedirectToDifferentDomain />
  )} />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem('authDetails') ?
      <Component {...props} isLoggedIn={true} angularUrl={config[environment].angularUrl} /> :
      <Component {...props} isLoggedIn={false} angularUrl={config[environment].angularUrl} />
  )} />
);

const RedirectToDifferentDomain = () => {
  useEffect(() => {
    let configObject = config[environment];
    console.log('configObject ============', configObject);
    window.location.replace(`${configObject.angularUrl}/signup?redirectTo=permitCreate`); // Replace with your desired domain
  }, []);

  return null; // Render nothing as the redirection is immediate
};

function App() {
  // const parseURL = async () => {
  //   const parsed = await queryString.parse(window.location.search, { ignoreQueryPrefix: true });
  //   await localStorage.setItem('authDetails', JSON.stringify(parsed));
  //   const authDetails = await JSON.parse(localStorage.getItem('authDetails'));
  //   // if (authDetails && authDetails.token) {
  //   //   if (authDetails.permit_id) {
  //   //     history.push("/permits/update")
  //   //   } else if (authDetails.user_id) {
  //   //     history.push("/quickpermits/create")
  //   //   } else {
  //   //     history.push("/permits/create")
  //   //   }
  //   //   window.location.reload();
  //   // }
  // }

  useEffect(() => {
    const parseURL = async () => {
      if (window.location.search) {
        await localStorage.removeItem('authDetails');
        const parsed = await queryString.parse(window.location.search, { ignoreQueryPrefix: true });
        await localStorage.setItem('authDetails', JSON.stringify(parsed));
        const authDetails = await JSON.parse(localStorage.getItem('authDetails'));
        if (authDetails && authDetails.token) {
          if (authDetails.permit_id) {
            history.push("/permits/update")
          } else if(window.location.href.includes("/permits/create")) {
            history.push("/permits/create")
          } else if (authDetails.quickpermit) {
            history.push("/quickpermits/create")
          } else if (authDetails.created_from) {
            history.push("/permits/create")
          } else {
            history.push("/permits/permit-information")
          }
          window.location.reload();
        }
      }
    };

    parseURL();
  }, []);


  return (
    <Router>
      <div>
        <Switch>
          <PrivateRoute exact path={"/permits/create"} component={CreatePermits} />
          <PrivateRoute exact path={"/permits/update"} component={UpdatePermits} />
          <PrivateRoute exact path={"/quickpermits/create"} component={QuickPermits} />
          <PublicRoute exact path={"/order/:permitId"} component={OrderConfirm} />
          <PublicRoute exact path="/permits/permit-information" component={PermitInformation} />
          <PublicRoute exact path="/permits/account-information" component={PermitAccountDetails} />
          <PublicRoute exact path="/permits/payment-information" component={PermitCheckout} />
          <PublicRoute exact path="/logout" component={Logout} />
          {/* <PublicRoute exact path="/new/permit/account-detail" component={Login} />
          <PublicRoute exact path="/new/signup" component={Login} />
          <PublicRoute exact path="/permits/payment-information" component={Login} /> */}

          {/* <Route exact path={"/login"} component={Login} /> */}
          {/* <Route exact path={"/signup"} component={SignUp} /> */}
          {/* <Redirect from="/" to="/permits/create" /> */}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
