import { Modal } from 'antd';

const CityDetailsPopup = ({ isCityPopupOpen, handleOk, cityDetails }) => {
    return (
        <>
            <Modal
                footer={null}
                title={cityDetails.city_name}
                open={isCityPopupOpen}
                onOk={handleOk}
                onCancel={handleOk}
                width={1000}
            >
                <form action="#" className="basic-form">
                    <div className="popup-content">
                        <div className="description-block">
                            <div className="description-head">The  basics : <span className="highlight-text">CITY FEES + SERVICE FEES = TOTAL</span></div>
                            <p className="sub-text">
                                <span>Each city has different pricing and regulations.</span>
                                <span>Please read the content below carefully.</span>
                            </p>
                            <div className="description-head">What it costs</div>
                            <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{__html: cityDetails.fees_description || ''}} />
                            <div className="description-head">City Details</div>
                            <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{__html: cityDetails.description || ''}} />

                            <p ng-bind-html="permit.cityDes | trusted_text"></p>
                            {/* <!-- <p>{{ permit.cityHelpNote }}</p> --> */}
                        </div>
                    </div>
                </form>

            </Modal>
        </>
    );
};
export default CityDetailsPopup;
