import React, { useEffect, useState } from 'react'
import './permitInCustomerInformation.css';
import { useDispatch } from "react-redux";
import { getErrors } from './permitFormUtils'
import moment from 'moment';
import { getCityDetails } from "../../actions/permits";
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { useHistory } from "react-router-dom";

dayjs.extend(weekday)
dayjs.extend(localeData)

const PermitInCustomerInformation = ({
    me,
    handleSubmit,
    states = [],
    permitDetails = {},
    submitButtonText = 'Order my Permit',
    type,
    quickPermit,
    isLoggedIn,
    isGuest,
    angularUrl
}) => {
    const history = useHistory();
    const [permitValues, setPermitValues] = useState({});
    const [isUserDetailsEdit, setIsUserDetailsEdit] = useState(false);
    const [errList, setErrList] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log('permitDetails ========, ', permitDetails)
        if (permitDetails && (typeof permitDetails === "object") && (Object.keys(permitDetails).length !== 0)) {
            let prefilledDatas = {
                city_id: permitDetails.city_id,
                street_address: permitDetails.street_address,
                apartment: permitDetails.apartment,
                zipcode: permitDetails.zip_code,
                start_at: permitDetails.start_at ? moment(permitDetails.start_at, 'YYYY-MM-DD').format('MM-DD-YYYY') : '',
                end_at: permitDetails.end_at ? moment(permitDetails.end_at, 'YYYY-MM-DD').format('MM-DD-YYYY') : '',
                reason_id: permitDetails.reason_id,
                first_name: permitDetails.first_name || me?.UserOption?.first_name,
                last_name: permitDetails.last_name || me?.UserOption?.last_name,
                billing_address: permitDetails.billing_address || me?.UserOption?.mailing_address,
                billing_city: permitDetails.billing_city || me?.UserOption?.mailing_city,
                billing_zipcode: permitDetails.billing_zipcode || me?.UserOption?.mailing_zip,
                billing_phone: permitDetails.billing_phone || me?.UserOption?.phone,
                billing_phone_ext: permitDetails.billing_phone_ext || me?.UserOption?.phone_ext,
                billing_fax: permitDetails.billing_fax || me?.UserOption?.fax,
                created_from: "",
                state_id: permitDetails.state_id || me?.UserOption?.mailing_state,
                paymentMethod: permitDetails.payment_type === 'invoice' ? true : false,
                source: "MP",
                site_review: permitDetails.site_review,
                sign_removal: permitDetails.sign_removal,
                meter_numbers: permitDetails.meter_numbers,
                shipper_name: permitDetails.shipper_name,
                special_requirements: permitDetails.special_requirements,
                company_name: permitDetails.company_name || me?.UserOption?.company_name,
                authorize_card_id: permitDetails.authorize_card_id,
                authorize_customer_id: permitDetails.authorize_customer_id,
                user_id: permitDetails.user_id,
                card_number: '',
                card_expiration_date: '',
                termsandservice: quickPermit ? false : true,
                payment_method_token: permitDetails.authorize_customer_id,
                customerPaymentProfileId: permitDetails.authorize_card_id,
                permit_id: permitDetails.permit_id,
            }
            if (quickPermit) {
                prefilledDatas.quick_permit = 1
            }
            if (permitDetails.city_id) {
                dispatch(getCityDetails(permitDetails.city_id));
            }
            if (!permitDetails.first_name) {
                setIsUserDetailsEdit(true)
            }
            setPermitValues({ ...permitValues, ...prefilledDatas })
        } else {
            if (me) {
                let customerDatas = {
                    first_name: me?.UserOption?.first_name,
                    last_name: me?.UserOption?.last_name,
                    billing_address: me?.UserOption?.mailing_address,
                    billing_city: me?.UserOption?.mailing_city,
                    billing_phone_ext: me?.UserOption?.phone_ext,
                    billing_fax: me?.UserOption?.fax,
                    billing_zipcode: me?.UserOption?.mailing_zip,
                    state_id: me?.UserOption?.mailing_state,
                    billing_phone: me?.UserOption?.phone,
                    company_name: me?.UserOption?.company_name,
                    card_number: '',
                    card_expiration_date: ''
                }
                if (!me?.UserOption?.first_name) {
                    setIsUserDetailsEdit(true)
                }
                setPermitValues({ ...permitValues, ...customerDatas })
            }
        }
    }, [me, permitDetails])

    const handleChange = (e) => {
        if (e.target.name === 'city_id') {
            dispatch(getCityDetails(e.target.value));
        }
        if (e.target.name === 'card_number') {
            if (e.target.value.match("^[0-9 -]*$") !== null) {
                let value = e.target.value
                if (e.target.value.length === 4 || e.target.value.length === 9 || e.target.value.length === 14) {
                    value = `${e.target.value}-`
                }
                setPermitValues({ ...permitValues, [e.target.name]: value })
            }
        } else if (e.target.name === 'card_expiration_date') {
            if (e.target.value.match("^[0-9  / ]*$") !== null) {
                let value = e.target.value
                if (e.target.value.length === 2) {
                    value = `${e.target.value} / `
                }
                setPermitValues({ ...permitValues, [e.target.name]: value })
            }
        } else if (e.target.name === 'card_security_code') {
            if (e.target.value.match("^[0-9]*$") !== null) {
                setPermitValues({ ...permitValues, [e.target.name]: e.target.value })
            }
        } else {
            setPermitValues({ ...permitValues, [e.target.name]: e.target.value })
        }
    }

    const handleSubmitData = async (e) => {
        e.preventDefault();
        let errorData = await getErrors(permitValues, isGuest, quickPermit)
        console.log('errorData =====', errorData)
        if (JSON.stringify(errorData) === '{}') {
            setDisableSubmit(true)
            const values = JSON.parse(JSON.stringify(permitValues))
            values.isLoggedIn = quickPermit ? true : isLoggedIn;
            await handleSubmit(values)
            setTimeout(() => {
                setDisableSubmit(false)
            }, 3000)

        } else {
            if(errorData.billing_address || errorData.billing_city || errorData.billing_phone || errorData.billing_zipcode || errorData.first_name || errorData.last_name || errorData.street_address || errorData.zipcode || errorData.state_id) {
                setIsUserDetailsEdit(true)
            }
            setErrList(errorData)
        }
    }

    const handleCancelPermit = (e) => {
        e.preventDefault();
        history.push(`/permits/permit-information`)
        // if (type === 'update') {
        //     window.location.replace(`${angularUrl}/permits/${permitDetails?.permit_id}`);
        // } else {
        //     window.location.replace(`${angularUrl}/permits`);
        // }
    }

    return (
        <section className="permitCheckout common-content-wrapper common-dark-bg request-permit ng-scope">
            <div className="layout-container">
                {/*start page page-white*/}
                <div className="permit-steps">
                    {/*permit information content*/}
                    <div className="permit-content">
                        <form
                            // ng-submit="requestPermit.$valid && submitForm()"
                            className="basic-form ng-pristine ng-invalid ng-invalid-required ng-valid-pattern"
                            id="requestPermit"
                            name="requestPermit"
                            noValidate=""
                            autoComplete="off"
                        >
                            <input
                                style={{ display: "none" }}
                                type="text"
                                name="street_address"
                                value={permitValues.street_address || ''}
                            />
                            <input style={{ display: "none" }} type="text" name="apartment" value={permitValues.apartment || ''} />
                            {/* <div className="request-section-heading">PERMIT INFORMATION</div> */}
                            
                            <div className="request-section-heading odd-heading edit-btn-box">
                                CUSTOMER INFORMATION
                            </div>
                            <div className="layout-wrapper medium-grid">
                                    <div className="layout-sm-12" ng-show="editForm" style={{}}>
                                        <div className="layout-wrapper small-grid">
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="companyName">Company Name</label>
                                                    <input
                                                        id="companyName"
                                                        className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                                        type="text"
                                                        name="company_name"
                                                        value={permitValues.company_name || ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="firstName">
                                                        First Name <span className="color">*</span>
                                                    </label>
                                                    <input
                                                        id="firstName"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        type="text"
                                                        name="first_name"
                                                        required=""
                                                        value={permitValues.first_name || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.first_name ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.firstname.$invalid && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="lastName">
                                                        Last Name <span className="color">*</span>
                                                    </label>
                                                    <input
                                                        id="lastName"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        type="text"
                                                        name="last_name"
                                                        required=""
                                                        value={permitValues.last_name || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.last_name ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.lastname.$invalid && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="billingAddress">
                                                        Billing Address <span className="color">*</span>
                                                    </label>
                                                    <textarea
                                                        id="billingAddress"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        rows={3}
                                                        name="billing_address"
                                                        required=""
                                                        defaultValue={""}
                                                        value={permitValues.billing_address || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.billing_address ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingaddress.$invalid && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="city">
                                                        City <span className="color">*</span>
                                                    </label>
                                                    <input
                                                        id="city"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        type="text"
                                                        name="billing_city"
                                                        required=""
                                                        value={permitValues.billing_city || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.billing_city ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingcity.$invalid && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="state">
                                                        State <span className="color">*</span>
                                                    </label>
                                                    <select
                                                        name="state_id"
                                                        id="billingstate"
                                                        className="default-select-box ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        ng-options="eachState.state_id as eachState.state_name for eachState in permit.states"
                                                        required=""
                                                        value={permitValues.state_id || ''}
                                                        onChange={handleChange}
                                                    >
                                                        <option
                                                            value=""
                                                            disabled=""
                                                            className=""
                                                            selected="selected"
                                                        >
                                                            Select a state
                                                        </option>
                                                        {states.map(state =>
                                                            <option key={state?.state_id} label={`${state?.state_name}`} value={state?.state_id}>
                                                                {state?.state_name}
                                                            </option>
                                                        )}
                                                    </select>
                                                    <span
                                                        className={`form-error ${!errList.state_id ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingstate.$error.required && requestPermit.$submitted )"
                                                    >
                                                        Select a state
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="zip">
                                                        Postal Code <span className="color">*</span>
                                                    </label>
                                                    <input
                                                        id="billingzip"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern"
                                                        type="text"
                                                        name="billing_zipcode"
                                                        ng-pattern="/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *(\d[A-Z]\d)*)$/"
                                                        required=""
                                                        value={permitValues.billing_zipcode || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.billing_zipcode ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingzip.$error.required && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                    <span
                                                        className={`form-error ${!errList.invalidBillingZip ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingzip.$error.pattern && requestPermit.$submitted)"
                                                    >
                                                        Invalid postal code
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-2">
                                                <div className="form-group">
                                                    <label htmlFor="phone">
                                                        Phone <span className="color">*</span>
                                                    </label>
                                                    <input
                                                        id="phone"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        type="number"
                                                        name="billing_phone"
                                                        required=""
                                                        value={permitValues.billing_phone || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.billing_phone ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingphone.$invalid && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-2">
                                                <div className="form-group">
                                                    <label htmlFor="ext">Ext </label>
                                                    <input
                                                        id="ext"
                                                        className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                                        type="text"
                                                        name="billing_phone_ext"
                                                        value={permitValues.billing_phone_ext || ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="fax">
                                                        Fax <span> (optional)</span>
                                                    </label>
                                                    <input
                                                        id="fax"
                                                        className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                                        type="text"
                                                        name='billing_fax'
                                                        value={permitValues.billing_fax || ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            {isGuest ?
                                                <div className="layout-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="email">
                                                            Email <span className="color">*</span>
                                                            <span className="tooltip tooltip-top sm-width align-left-xs">
                                                                <i className="info-tooltip mpIcons-help" />
                                                                <span className="tooltip-content">
                                                                    We’re committed to your privacy. MovingPermits uses the information you provide to us to contact you about your permit status. For more information, check out our Terms of Service
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <input
                                                            id="email"
                                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                                            type="text"
                                                            name='email'
                                                            value={permitValues.email || ''}
                                                            onChange={handleChange}
                                                        />
                                                        <span
                                                            className={`form-error ${!errList.email ? 'ng-hide' : ''}`}
                                                            ng-show="(requestPermit.lastname.$invalid && requestPermit.$submitted)"
                                                        >
                                                            This field is required
                                                        </span>

                                                    </div>
                                                </div> : null}
                                            {/* ngIf: ['mover', 'contractor'].indexOf(permit.userDetails.Classification.classification_name) > -1 */}
                                        </div>
                                    </div>
                            </div>

                            <div className="form-group action-btn-wrap">
                                <button
                                    mixular="click"
                                    eventlabel="Clicked Order my Permit button from request permit page"
                                    className="btn secondary-btn submit-btn xs-rounded-btn is-loader ng-binding"
                                    onClick={handleSubmitData}
                                    disabled={disableSubmit}
                                >
                                    {submitButtonText}
                                </button>
                                <button
                                    className="btn cancel-btn"
                                    onClick={handleCancelPermit}
                                >
                                    Back
                                </button>
                                <span
                                    ng-show="validationError"
                                    className={`form-error relative request-total-error ${JSON.stringify(errList) === '{}' ? 'ng-hide' : ''}`}
                                >
                                    Oops! Required field(s) missing. See above.
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>



    )
}

export default PermitInCustomerInformation