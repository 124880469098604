import React, { useState } from 'react'
import './permitInAccountDetailsForm.css';
import { getLoginErrors } from './authFormUtils'

const PermitInAccountDetailsForm = ({
    handleSubmit,
    submitButtonText = 'Continue',
    handleLoginSubmit,
    angularUrl
}) => {

    const [errList, setErrList] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [returningCustomers, setReturningCustomers] = useState({ email: '', password: '' });
    const [newCustomerCheckbox, setNewCustomerCheckbox] = useState({ email: '', password: '' });

    const handleChange = (e) => {
        setReturningCustomers({ ...returningCustomers, [e.target.name]: e.target.value })
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        let errorData = await getLoginErrors(returningCustomers)
        console.log('errorData =====', errorData)
        if (JSON.stringify(errorData) === '{}') {
            setDisableSubmit(true)
            await handleLoginSubmit(returningCustomers)
            setTimeout(() => {
                setDisableSubmit(false)
            }, 3000)

        } else {
            setErrList(errorData)
        }
    }

    const handleForgotPassword = () => {
        window.location.replace(`${angularUrl}/forgot-password`)
    }

    return (
        <section className="account-details common-content-wrapper common-dark-bg request-permit ng-scope">
            <div className="layout-container">

                <div className="permit-steps">
                    {/*permit information content*/}
                    <div className="permit-content">
                        <form
                            // ng-submit="requestPermit.$valid && submitForm()"
                            className="basic-form ng-pristine ng-invalid ng-invalid-required ng-valid-pattern"
                            id="requestPermit"
                            name="requestPermit"
                            noValidate=""
                            autoComplete="off"
                        >
                            <div className="request-section-heading">ACCOUNT DETAILS</div>
                            <div className="layout-wrapper medium-grid">
                                <div className="layout-sm-6 verticalLine">
                                    <div className="form-group">

                                        <label>I'm A New Customer </label>
                                    </div>
                                    <span>Register with us for faster checkout, to track the status of your order and more. You can also checkout as guest. </span>
                                    <br />
                                    <br />
                                    <br />
                                    <br />

                                    <div className="form-group radioStyle">
                                        <div onChange={(e) => setNewCustomerCheckbox(e.target.value)}>
                                            <input type="radio" value="GUEST" name="gender" className='radioInput' /> Checkout as a guest
                                            <br />

                                            <input type="radio" value="REGISTER" name="gender" /> Register an account
                                        </div>
                                        <br />
                                        <br />
                                        <button
                                            mixular="click"
                                            eventlabel="Clicked Order my Permit button from request permit page"
                                            className="btn secondary-btn submit-btn xs-rounded-btn is-loader ng-binding"
                                            onClick={() => handleSubmit(newCustomerCheckbox)}
                                            // disabled={disableSubmit}
                                        >
                                            {submitButtonText}
                                        </button>
                                    </div>

                                </div>
                                <div className="layout-sm-6">
                                    <div className="form-group">
                                        <label>I'm A Returning Customer </label>
                                    </div>
                                    <span>To continue, please enter your email address and password that you use for your account.</span>
                                    <br />
                                    <br />
                                    <div className="form-group">
                                        <label className='ft-14' htmlFor="MeterNumbers">Email Address</label>
                                        <input
                                            id="email"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                            name='email'
                                            value={returningCustomers.email || ''}
                                            onChange={handleChange}
                                        />
                                        <span
                                            className={`form-error ${!(errList.email || errList.invalidEmail) ? 'ng-hide' : ''}`}
                                            ng-show="(requestPermit.lastname.$invalid && requestPermit.$submitted)"
                                        >
                                            {errList.email ? "This field is required" : "Please enter valid email"}
                                        </span>
                                    </div>
                                    <br />
                                    <div className="form-group">
                                        <label className='ft-14' htmlFor="MeterNumbers">Password</label>
                                        <input
                                            name="password"
                                            id="password"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="password"
                                            onChange={handleChange}
                                            value={returningCustomers.password || ''}
                                        />
                                                                                <span
                                            className={`form-error ${!errList.password ? 'ng-hide' : ''}`}
                                            ng-show="(requestPermit.lastname.$invalid && requestPermit.$submitted)"
                                        >
                                            This field is required
                                        </span>
                                    </div>
                                    <br />

                                    <button
                                        mixular="click"
                                        eventlabel="Clicked Order my Permit button from request permit page"
                                        className="btn secondary-btn submit-btn xs-rounded-btn is-loader ng-binding"
                                        onClick={handleLogin}
                                        disabled={disableSubmit}
                                    >
                                        {submitButtonText}
                                    </button>
                                    <span onClick={handleForgotPassword} className='forgot-pass' htmlFor="MeterNumbers">Forgot Password?</span>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                {/*end page page-white*/}
            </div>

        </section>



    )
}

export default PermitInAccountDetailsForm