import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import {
    getSettingList,
    getStates,
    getClassification,
    getCities,
    getMe,
    getReasons,
    updatePermit,
    getCardDetails,
    getPermitDetails,
} from "../../../actions/permits";
import PermitForm from '../../../components/permitForm/permitsForm'
import './updatePermits.css'
import Header from '../../../components/header/header'
import { Spin, notification } from 'antd';
import { TOASTER } from "../../../actions/types";


const UpdatePermits = () => {
    // const history = useHistory();
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, placement, title, description) => {
        api[type]({
            message: title,
            description,
            placement
        });
    };
    const { cities, states, me, reasons, cardDetails, cityDetails, permitDetails, permitUpdate, setting } = useSelector(state => state.permits);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSettingList({ setting_key: "angular_url" }));
        dispatch(getStates());
        dispatch(getCities());
        dispatch(getClassification());
        dispatch(getMe());
        dispatch(getReasons());
        const authDetails = JSON.parse(localStorage.getItem('authDetails'));
        if (authDetails.permit_id) {
            dispatch(getPermitDetails(authDetails.permit_id));
        }
        document.title = 'MovingPermits.com - Request permit';
    }, [dispatch]);

    useEffect(() => {
        console.log('permitDetails 1111111111111111111111111', permitDetails)
        if(permitDetails?.data?.user_id) {
            dispatch(getCardDetails(permitDetails?.data?.user_id));
        }
    }, [permitDetails]);

    useEffect(() => {
        console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
        if (setting && setting.option_value) {
            if (window.location.pathname !== '/permits/update') {
                window.location.replace(`${setting.option_value}/login`);
            }
        }
    }, [setting]);

    useEffect(() => {
        console.log('permitUpdate ==========', permitUpdate)
        if (permitUpdate?.permit_id) {
            openNotificationWithIcon('success', 'bottomRight', TOASTER.SUCCESS_TITLE, TOASTER.SUCCESS_UPDATE_MESSAGE)
            setTimeout(() => {
                window.location.replace(`${me.angular_url}/permits/${permitUpdate?.permit_id}`);
            }, 1000)
        }
        if (permitUpdate?.failed) {
            openNotificationWithIcon('error', 'bottomRight', TOASTER.ERROR_TITLE, permitUpdate.message || TOASTER.ERROR_UPDATE_MESSAGE)
        }

    }, [permitUpdate]);

    return (
        <>
            {contextHolder}
            {(JSON.stringify(me) !== '{}' && permitDetails && permitDetails.data) ?
                <>
                    <Header me={me} setting={setting} />
                    <div className="main-layout ng-scope" ui-view="" style={{}}>
                        <section className="breadcrumbs ng-scope">
                            <div className="layout-container">
                                <ul>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => window.location.replace(`${me.angular_url}/`)}>
                                            Home
                                        </span>
                                    </li>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => window.location.replace(`${me.angular_url}/permits`)}>
                                            Permits
                                        </span>
                                    </li>
                                    <li>Request a permit</li>
                                </ul>
                            </div>
                        </section>
                        <PermitForm
                            isLoggedIn = {true}
                            cities={cities ? cities.rows : []}
                            reasons={reasons ? reasons.rows : []}
                            me={me}
                            states={states ? states.rows : []}
                            handleSubmit={(data) => dispatch(updatePermit(permitDetails?.data?.permit_id, data))}
                            customerProfileId={cardDetails?.profile?.customerProfileId}
                            cardDetails={cardDetails?.profile?.paymentProfiles}
                            cityDetails={cityDetails?.data}
                            permitDetails={permitDetails && permitDetails.data ? permitDetails.data : {}}
                            submitButtonText='Update Permit'
                            type='update'
                        />
                    </div>
                </>
                :
                <Spin className='loader' size="large" />
            }
        </>
    )
}

export default UpdatePermits