import {
  GET_SETTINGS,
  GET_STATES,
  GET_CITIES,
  GET_CLASSIFICATION,
  GET_ME,
  GET_REASON,
  GET_CARD_DETAILS,
  GET_CITY_DETAILS,
  SUBMIT_PERMIT,
  GET_PERMIT_DETAILS,
  UPDATE_PERMIT,
  GET_USER_DETAILS,
  PASSWORD_UPDATE,
  PERMIT_INFORMATION,
  LOGIN,
  PERMIT_STEPONE,
  SIGNUP
} from "../actions/types";

const initialState = {
  setting: {},
  states: {},
  cities: {},
  classifications: {},
  me: {},
  reasons: {},
};

const permitReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // console.log('state ======', state)
  // console.log('action ======', action)
  switch (type) {
    case GET_SETTINGS:
      return { ...state, setting: payload.data };
    case GET_STATES:
      return { ...state, states: payload.data };
    case GET_CITIES:
      return { ...state, cities: payload.data };
    case GET_CLASSIFICATION:
      return { ...state, classifications: payload.data };
    case GET_ME:
      return { ...state, me: payload.data };
    case GET_REASON:
      return { ...state, reasons: payload.data };
    case SUBMIT_PERMIT:
      return { ...state, permitSubmit: payload.data };
    case GET_CARD_DETAILS:
      return { ...state, cardDetails: payload };
    case GET_CITY_DETAILS:
      return { ...state, cityDetails: payload };
    case GET_PERMIT_DETAILS:
      return { ...state, permitDetails: payload };
    case UPDATE_PERMIT:
      return { ...state, permitUpdate: payload.data };
    case GET_USER_DETAILS:
      return { ...state, userDetails: payload };
    case PASSWORD_UPDATE:
      return { ...state, passwordUpdate: payload.data };
    case PERMIT_INFORMATION:
      return { ...state, permitInformation: payload };
    case LOGIN:
      return { ...state, loginData: payload.data };
    case SIGNUP:
      return { ...state, signUpData: payload.data };
    case PERMIT_STEPONE:
      return { ...state, permitInformation: {...state.permitInformation, permit_id: payload.data.permit_id, user_id: payload.data.user_id} };
    default:
      return state;
  }
};

export default permitReducer;