import moment from 'moment';

const emailRegex = /\S+@\S+\.\S+/;

export const getLoginErrors = (permitValues) => {
    let errs = {}
    console.log('emailRegex ========', emailRegex.test(permitValues.email))

    if (!permitValues.email) {
        errs.email = true
    } else if (!emailRegex.test(permitValues.email)) {
        errs.invalidEmail = true
    }
    if (!permitValues.password) {
        errs.password = true
    }
    return errs
}


export const processDatas = (permitValues, isRush, cityDetails) => {
    if (permitValues.card_number) {
        permitValues.card_number = permitValues.card_number.replace(/-/g, "");
    }
    if (permitValues.card_security_code) {
        permitValues.card_security_code = permitValues.card_security_code.replace(/\//g, "");
    }
    if (permitValues.card_expiration_date) {
        permitValues.card_expiration_date = permitValues.card_expiration_date.replace(/ /g, "");
    }
    if (permitValues.end_at) {
        permitValues.end_at = moment(permitValues.end_at, 'MM-DD-YYYY').format('YYYY-MM-DD');
    }
    if (permitValues.start_at) {
        permitValues.start_at = moment(permitValues.start_at, 'MM-DD-YYYY').format('YYYY-MM-DD');
    }
    if (isRush && cityDetails) {
        permitValues.counting_system = cityDetails.counting_system;
        permitValues.expedited_service_charge = cityDetails.expedited_service_charge;
        permitValues.lead_time = cityDetails.lead_time;
    }
    permitValues.payment_type = permitValues.paymentMethod ? '' : 'card'
    permitValues.source_site = 'react'
    return permitValues
}
