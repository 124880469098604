import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import './createPermits.css'
import {
    getSettingList,
    getStates,
    getClassification,
    getCities,
    getMe,
    getReasons,
    submitPermit,
    getCardDetails,
    getPermitDetails,
} from "../../../actions/permits";
import PermitForm from '../../../components/permitForm/permitsForm'
import Header from '../../../components/header/header'
import { Spin, notification } from 'antd';
import { TOASTER } from "../../../actions/types";

const CreatePermits = () => {
    const history = useHistory();
    const [api, contextHolder] = notification.useNotification();
    const [authDetailsState, setAuthDetailsState] = useState({});

    const openNotificationWithIcon = (type, placement, title, description) => {
        api[type]({
            message: title,
            description,
            placement
        });
    };

    const { cities, states, me, reasons, cardDetails, cityDetails, permitSubmit, setting, permitDetails } = useSelector(state => state.permits);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSettingList({ setting_key: "angular_url" }));
        dispatch(getStates());
        dispatch(getCities());
        dispatch(getReasons());
        const authDetails = JSON.parse(localStorage.getItem('authDetails'));
        setAuthDetailsState(authDetails)
        if (authDetails && authDetails.token) {
            dispatch(getClassification());
            dispatch(getMe());
            if (authDetails && authDetails.created_from) {
                dispatch(getPermitDetails(authDetails.created_from));
            } else {
                dispatch(getCardDetails());
            }
        }
        document.title = 'MovingPermits.com - Request permit';
    }, [dispatch]);

    useEffect(() => {
        console.log('permitDetails 33333333333', permitDetails)
        if (permitDetails?.data?.user_id) {
            dispatch(getCardDetails(permitDetails?.data?.user_id));
        }
    }, [permitDetails]);

    useEffect(() => {
        console.log('CCCCCCCCC', window.location.pathname)
        if (setting && setting.option_value) {
            if (window.location.pathname !== '/permits/create' && window.location.pathname !== '/permits/update') {
                window.location.replace(`${setting.option_value}/login`);
            }
            if (!authDetailsState) {
                window.location.replace(`${setting.option_value}/signup?redirectTo=permitCreate`);
            }
        }
    }, [setting]);

    useEffect(() => {
        console.log('permitSubmit ==========', permitSubmit)
        if (permitSubmit?.permit_id) {
            if (permitSubmit.permit_status === "draft") {
                openNotificationWithIcon('success', 'bottomRight', TOASTER.LOGIN_TITLE, TOASTER.LOGIN_MESSAGE)
                setTimeout(() => {
                    // history.push(`/order/${permitSubmit?.permit_id}`);
                    window.location.replace(`${setting?.option_value}/login?permit_id=${permitSubmit?.permit_id}`);
                    // window.location.replace(`/order/${permitSubmit?.permit_id}`);
                }, 2000)
                return;
            }
            openNotificationWithIcon('success', 'bottomRight', TOASTER.SUCCESS_TITLE, TOASTER.SUCCESS_MESSAGE)
            setTimeout(() => {
                history.push(`/order/${permitSubmit?.permit_id}`);
                // window.location.replace(`/order/${permitSubmit?.permit_id}`);
            }, 1000)
        }
        if (permitSubmit?.failed) {
            openNotificationWithIcon('error', 'bottomRight', TOASTER.ERROR_TITLE, permitSubmit.message || TOASTER.ERROR_MESSAGE)
        }
    }, [permitSubmit]);

    console.log('settings.JS ======================', setting)
    console.log('cities, states, me, reasons.JS ======================', cities, states, reasons, history)

    return (
        <>
            {contextHolder}
            {((!authDetailsState && JSON.stringify(me) === '{}') || (JSON.stringify(me) !== '{}')) && (setting && setting.option_value) ?
                <>
                    <Header me={me} setting={setting} />
                    <div className="main-layout ng-scope" ui-view="" style={{}}>
                        <section className="breadcrumbs ng-scope">
                            <div className="layout-container">
                                <ul>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => window.location.replace(`${me.angular_url}/`)}>
                                            Home
                                        </span>
                                    </li>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => window.location.replace(`${me.angular_url}/permits`)}>
                                            Permits
                                        </span>
                                    </li>
                                    <li>Request a permit</li>
                                </ul>
                            </div>
                        </section>
                        <PermitForm
                            isLoggedIn={authDetailsState && authDetailsState.token ? true : false}
                            cities={cities ? cities.rows : []}
                            reasons={reasons ? reasons.rows : []}
                            me={me}
                            states={states ? states.rows : []}
                            handleSubmit={(data) => dispatch(submitPermit(data))}
                            customerProfileId={cardDetails?.profile?.customerProfileId}
                            cardDetails={cardDetails?.profile?.paymentProfiles}
                            cityDetails={cityDetails?.data}
                            submitButtonText='Order my Permit'
                            permitDetails={permitDetails && permitDetails.data ? permitDetails.data : {}}
                            type='create'
                        />
                    </div>
                </>
                :
                <Spin className='loader' size="large" />
            }
        </>
    )
}

export default CreatePermits