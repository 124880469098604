import React, { useEffect, useState } from 'react'
import './permitsForm.css';
import {
    DatePicker,
    Card,
} from 'antd';
import { useDispatch } from "react-redux";
import { getErrors, processDatas } from './permitFormUtils'
import moment from 'moment';
import { getCityDetails } from "../../actions/permits";
import RushHourPopup from "../../components/rushHourPopup/rushHourPopup";
import CityDetailsPopup from "../../components/cityDetailsPopup/cityDetailsPopup";
import SignReviewPopup from "../../components/signReviewPopup/signReviewPopup";
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)

const PermitForm = ({
    reasons = [],
    me,
    cities = [],
    handleSubmit,
    states = [],
    cardDetails = [],
    customerProfileId,
    cityDetails = {},
    permitDetails = {},
    submitButtonText = 'Order my Permit',
    type,
    quickPermit,
    isLoggedIn
}) => {

    const [permitValues, setPermitValues] = useState({ reason_id: '52', sign_removal: false, site_review: false });
    const [isUserDetailsEdit, setIsUserDetailsEdit] = useState(false);
    const [isSavedCards, setIsSavedCards] = useState(isLoggedIn ? true : false);
    const [errList, setErrList] = useState({});
    const [isRushModalOpen, setIsRushModalOpen] = useState(false);
    const [isSignModalOpen, setIsSignModalOpen] = useState(false);
    const [isRush, setIsRush] = useState(false);
    const [diffDaysNumber, setDiffDaysNumber] = useState(null);
    const [isCityPopupOpen, setIsCityPopupOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);


    const dispatch = useDispatch();

    useEffect(() => {
        console.log('permitDetails ========, ', permitDetails)
        if (permitDetails && (typeof permitDetails === "object") && (Object.keys(permitDetails).length !== 0)) {
            console.log('permitDetails 222222222222222222222========', permitDetails)
            let prefilledDatas = {
                city_id: permitDetails.city_id,
                street_address: permitDetails.street_address,
                apartment: permitDetails.apartment,
                zipcode: permitDetails.zip_code,
                start_at: permitDetails.start_at ? moment(permitDetails.start_at, 'YYYY-MM-DD').format('MM-DD-YYYY') : '',
                end_at: permitDetails.end_at ? moment(permitDetails.end_at, 'YYYY-MM-DD').format('MM-DD-YYYY') : '',
                reason_id: permitDetails.reason_id,
                first_name: permitDetails.first_name,
                last_name: permitDetails.last_name,
                billing_address: permitDetails.billing_address,
                billing_city: permitDetails.billing_city,
                billing_zipcode: permitDetails.billing_zipcode,
                billing_phone: permitDetails.billing_phone,
                billing_phone_ext: permitDetails.billing_phone_ext,
                billing_fax: permitDetails.billing_fax,
                created_from: "",
                state_id: permitDetails.state_id,
                paymentMethod: permitDetails.payment_type === 'invoice' ? true : false,
                source: "MP",
                site_review: permitDetails.site_review,
                sign_removal: permitDetails.sign_removal,
                meter_numbers: permitDetails.meter_numbers,
                shipper_name: permitDetails.shipper_name,
                special_requirements: permitDetails.special_requirements,
                company_name: permitDetails.company_name,
                authorize_card_id: permitDetails.authorize_card_id,
                authorize_customer_id: permitDetails.authorize_customer_id,
                user_id: permitDetails.user_id,
                card_number: '',
                card_expiration_date: '',
                termsandservice: quickPermit ? false : true,
                payment_method_token: permitDetails.authorize_customer_id,
                customerPaymentProfileId: permitDetails.authorize_card_id,
            }
            if (permitDetails.start_at) {
                let date1 = moment(permitDetails.start_at, 'YYYY-MM-DD');
                let date2 = moment();
                let diffDays = date1.diff(date2, 'days')
                console.log('diffDays start_at=======', diffDays)
                if (diffDays < 4) {
                    setIsRush(true)
                }
            }
            if (quickPermit) {
                prefilledDatas.quick_permit = 1
            }
            if (permitDetails.city_id) {
                dispatch(getCityDetails(permitDetails.city_id));
            }
            if (permitDetails.end_at) {
                let date1 = moment(permitDetails.end_at);
                let date2 = moment(permitDetails.start_at);
                let diffDays = date1.diff(date2, 'days')
                console.log('diffDays end_at=======', diffDays)
                setDiffDaysNumber(diffDays + 1)
            }
            if (!permitDetails.first_name || quickPermit) {
                setIsUserDetailsEdit(true)
            }
            setPermitValues({ ...permitValues, ...prefilledDatas })
        } else {
            if (me) {
                let customerDatas = {
                    first_name: me?.UserOption?.first_name,
                    last_name: me?.UserOption?.last_name,
                    billing_address: me?.UserOption?.mailing_address,
                    billing_city: me?.UserOption?.mailing_city,
                    billing_phone_ext: me?.UserOption?.phone_ext,
                    billing_fax: me?.UserOption?.fax,
                    billing_zipcode: me?.UserOption?.mailing_zip,
                    state_id: me?.UserOption?.mailing_state,
                    billing_phone: me?.UserOption?.phone,
                    company_name: me?.UserOption?.company_name,
                    card_number: '',
                    card_expiration_date: ''
                }
                if (!me?.UserOption?.first_name || quickPermit) {
                    setIsUserDetailsEdit(true)
                }
                setPermitValues({ ...permitValues, ...customerDatas })
            }
        }
    }, [me, permitDetails])

    const handleKeyDown = (e) => {
        console.log('e.keyCode ======', e.keyCode)
        console.log('e.keyCode ======', e.target.name)
        console.log('e.keyCode ======', e.target.value)
        if (e.keyCode === 8) {
            if (permitValues.card_number) {
                if (permitValues.card_number.length === 5 || permitValues.card_number.length === 10 || permitValues.card_number.length === 15) {
                    e.preventDefault();
                    setPermitValues({ ...permitValues, [e.target.name]: permitValues.card_number.slice(0, -2) })
                }
            }
            if (permitValues.card_expiration_date) {
                console.log('permitValues.card_expiration_date.length ======', permitValues.card_expiration_date.length)
                if (permitValues.card_expiration_date.length === 5) {
                    e.preventDefault();
                    setPermitValues({ ...permitValues, [e.target.name]: permitValues.card_expiration_date.slice(0, -4) })
                }
            }
        }
    };

    const handleCardChange = (card) => {
        console.log('card =======', card)
        setPermitValues({
            ...permitValues,
            payment_method_token: customerProfileId,
            customerPaymentProfileId: card.customerPaymentProfileId,
        })
    }

    const handleChange = (e) => {
        console.log('e.target =====', e.target)
        console.log('e.target.value =====', e.target.value)
        console.log('e.target.name =====', e.target.name)
        if (e.target.name === 'city_id') {
            dispatch(getCityDetails(e.target.value));
        }
        if (e.target.name === 'card_number') {
            console.log('111111111111111111111111', e.target.value.match("^[0-9]*$"))
            if (e.target.value.match("^[0-9 -]*$") !== null) {
                let value = e.target.value
                if (e.target.value.length === 4 || e.target.value.length === 9 || e.target.value.length === 14) {
                    value = `${e.target.value}-`
                }
                setPermitValues({ ...permitValues, [e.target.name]: value })
            }
        } else if (e.target.name === 'card_expiration_date') {
            if (e.target.value.match("^[0-9  / ]*$") !== null) {
                let value = e.target.value
                if (e.target.value.length === 2) {
                    value = `${e.target.value} / `
                }
                setPermitValues({ ...permitValues, [e.target.name]: value })
            }
        } else if (e.target.name === 'card_security_code') {
            console.log('Length========', e.target.value)
            if (e.target.value.match("^[0-9]*$") !== null) {
                console.log('Length 222222222222========', e.target.value)
                setPermitValues({ ...permitValues, [e.target.name]: e.target.value })
            }
        } else {
            setPermitValues({ ...permitValues, [e.target.name]: e.target.value })
        }
    }

    const handleCheckBoxChange = (e) => {
        console.log('e =====', e)
        console.log('e.target =====', e.target)
        console.log('e.target.value =====', e.target.checked)
        console.log('e.target.name =====', e.target.name)
        setPermitValues({ ...permitValues, [e.target.name]: e.target.checked })
    }

    const handleSiteSignChange = (data) => {
        setPermitValues({ ...permitValues, ...data })
        setIsSignModalOpen(false)
    }

    const addWeekdays = (days, include_weekend) => {
        var date = moment();
        while (days > 0) {
            date = date.add(1, 'days');
            if (include_weekend) { // decrease "days" only if it's a weekday.
                days -= 1;
            } else {
                if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                    days -= 1;
                }
            }
        }
        return date;
    }

    const handleDateChange = (date, dateString, fieldName) => {
        console.log('date, dateString =====', date, dateString)
        console.log('lead_time =====', fieldName, cityDetails.lead_time)

        let dateValues = {}
        if (fieldName === 'start_at' && cityDetails.lead_time) {
            const includeWeekend = (cityDetails?.counting_system === 'calendar_days') ? true : false;
            const cityExpeditedDate = addWeekdays(cityDetails.lead_time, includeWeekend);
            const expeditedDate = cityExpeditedDate.format('MM/DD/YYYY');
            if (moment(dateString) > moment(expeditedDate)) {
                setIsRush(false)
            } else {
                setIsRushModalOpen(true)
                setIsRush(true)
            }
        }

        if (fieldName === 'start_at') {
            dateValues.start_at = dateString
            dateValues.end_at = ''
            setPermitValues({ ...permitValues, ...dateValues })

        } else if (fieldName === 'end_at') {
            if (permitValues.start_at) {
                let date1 = moment(dateString);
                let date2 = moment(permitValues.start_at);
                let diffDays = date1.diff(date2, 'days')
                console.log('diffDays =======', diffDays)
                setDiffDaysNumber(diffDays + 1)
                setPermitValues({ ...permitValues, [fieldName]: dateString })
            }
        } else {
            setPermitValues({ ...permitValues, [fieldName]: dateString })
        }
        if (fieldName === 'end_at') {
            if (cityDetails?.sign_removal || cityDetails?.site_review) {
                setIsSignModalOpen(true)
            }
        }
    }

    const handleSubmitData = async (e) => {
        e.preventDefault();
        let errorData = await getErrors(permitValues, isSavedCards, isLoggedIn, quickPermit)
        console.log('errorData =====', errorData)
        if (JSON.stringify(errorData) === '{}') {
            setDisableSubmit(true)
            console.log('22222222222222222222222222222222')
            const values = JSON.parse(JSON.stringify(permitValues))
            let finalDatas = await processDatas(values, isRush, cityDetails)
            finalDatas.isLoggedIn = quickPermit ? true : isLoggedIn;
            if(permitDetails.permit_status === 'draft') {
                finalDatas.permit_status = permitDetails.permit_status;
            }
            await handleSubmit(finalDatas)
            setTimeout(() => {
                setDisableSubmit(false)
            }, 3000)

        } else {
            console.log('111111111111111111111111111111')
            if(errorData.billing_address || errorData.billing_city || errorData.billing_phone || errorData.billing_zipcode || errorData.first_name || errorData.last_name || errorData.street_address || errorData.zipcode || errorData.state_id) {
                setIsUserDetailsEdit(true)
            }
            setErrList(errorData)
        }
    }

    const handleCancelPermit = (e) => {
        e.preventDefault();
        if (type === 'update') {
            window.location.replace(`${me.angular_url}/permits/${permitDetails?.permit_id}`);
        } else {
            window.location.replace(`${me.angular_url}/permits`);
        }
    }

    console.log('errorList =====', errList)

    console.log('permitValues ========', permitValues)
    console.log('permitValues card_number========', permitValues.card_number)
    return (


        <section className="common-content-wrapper common-dark-bg request-permit ng-scope">
            <div className="layout-container">
                {/*start page-title*/}
                <div className="page-title">
                    <h2 className="content-primary-title clear-margins">
                        Request a Permit
                    </h2>
                    <span className="content-sub-head">
                        Fill out the simple form below to get your permit started. We'll
                        contact you if we need more information.
                    </span>
                </div>
                {/*end page-title*/}
                {/*start page page-white*/}
                <div className="permit-steps">
                    {/*permit information content*/}
                    <div className="permit-content">
                        <form
                            // ng-submit="requestPermit.$valid && submitForm()"
                            className="basic-form ng-pristine ng-invalid ng-invalid-required ng-valid-pattern"
                            id="requestPermit"
                            name="requestPermit"
                            noValidate=""
                            autoComplete="off"
                        >
                            <input
                                style={{ display: "none" }}
                                type="text"
                                name="street_address"
                                value={permitValues.street_address || ''}
                            />
                            <input style={{ display: "none" }} type="text" name="apartment" value={permitValues.apartment || ''} />
                            <div className="request-section-heading">PERMIT INFORMATION</div>
                            <div className="form-heading">
                                <span>Where</span> do you need the permit?
                            </div>
                            <div className="layout-wrapper medium-grid">
                                <div className="layout-sm-5">
                                    <div className="form-group">
                                        <label>
                                            City <span className="color">*</span>
                                            <span className="tooltip tooltip-top sm-width align-left-xs">
                                                <i className="info-tooltip mpIcons-help" />
                                                <span className="tooltip-content">
                                                    Choose a city and we'll explain how the permit works.
                                                </span>
                                            </span>
                                        </label>
                                        <select
                                            name="city_id"
                                            id="city"
                                            onChange={handleChange}
                                            className="default-select-box ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                            required=""
                                            value={permitValues.city_id || ''}
                                        >
                                            <option
                                                value=""
                                                disabled=""
                                                className=""
                                            // selected="selected"
                                            >
                                                Choose from our cities
                                            </option>
                                            {cities.map(city =>
                                                <option key={city?.city_id} label={`${city?.city_name}, ${city?.State?.state_code}`} value={city?.city_id}>
                                                    {`${city?.city_name}, ${city?.State?.state_code}`}
                                                </option>
                                            )}
                                        </select>
                                        <span className={`form-error ${!errList.city_id ? 'ng-hide' : ''}`}>
                                            Select a city
                                        </span>
                                        {permitValues.city_id && cityDetails ?
                                            <button
                                                type="button"
                                                className="btn secondary-btn xs-rounded-btn view-city-hints-btn ng-binding "
                                                onClick={() => setIsCityPopupOpen(true)}
                                            >
                                                <span />
                                                View {cityDetails.city_name} Pricing &amp; Details
                                            </button> : null}
                                        <span className="arrow-splash" ng-show="splashAlert && permit.userDetails.permit_booked == 0" ></span>
                                    </div>
                                </div>
                                <div className="layout-sm-7">
                                    <div className="form-group">
                                        <label htmlFor="address">
                                            Street Address <span className="color">*</span>
                                        </label>
                                        <input
                                            id="address"
                                            className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                            type="text"
                                            name="street_address"
                                            placeholder="Enter street address"
                                            required=""
                                            value={permitValues.street_address || ''}
                                            onChange={handleChange}
                                        />
                                        <span
                                            className={`form-error ${!errList.street_address ? 'ng-hide' : ''}`}
                                            ng-show="(requestPermit.street_address.$invalid && requestPermit.$submitted)"
                                        >
                                            This field is required
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="layout-wrapper medium-grid">
                                <div className="layout-sm-4 layout-md-3">
                                    <div className="form-group">
                                        <label htmlFor="Apartment">Apartment#</label>
                                        <input
                                            id="Apartment"
                                            name="apartment"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                            onChange={handleChange}
                                            value={permitValues.apartment || ''}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="layout-sm-4 layout-md-3 ng-hide"
                                    ng-show="permit.source == 'CP'"
                                >
                                    <div className="form-group">
                                        <label htmlFor="additional_location_info">
                                            Additional location info#
                                        </label>
                                        <input
                                            id="additional_location_info"
                                            name="additional_location_info"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="layout-sm-5 layout-md-3">
                                    <div className="form-group mb-2x">
                                        <label htmlFor="zip">
                                            Postal Code <span className="color">*</span>
                                        </label>
                                        <input
                                            id="zip"
                                            placeholder="Enter postal code"
                                            name="zipcode"
                                            className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern"
                                            type="text"
                                            ng-pattern="/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *(\d[A-Z]\d)*)$/"
                                            required=""
                                            onChange={handleChange}
                                            value={permitValues.zipcode || ''}
                                        />
                                        <span
                                            className={`form-error ${!errList.zipcode ? 'ng-hide' : ''}`}
                                            ng-show="(requestPermit.zipcode.$error.required  && requestPermit.$submitted)"
                                        >
                                            This field is required
                                        </span>
                                        <span
                                            className={`form-error ${!errList.invalidZip ? 'ng-hide' : ''}`}
                                            ng-show="(requestPermit.zipcode.$error.pattern && requestPermit.$submitted)"
                                        >
                                            Invalid postal code
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-heading">
                                <span>When</span> do you need the permit?
                            </div>
                            <div className="layout-wrapper medium-grid">
                                <div className="layout-sm-3 layout-md-2">
                                    <div className="form-group">
                                        <label>
                                            Start Date <span className="color">*</span>
                                        </label>
                                        {type === 'update' && permitValues.start_at ?
                                            <DatePicker
                                                allowClear={false}
                                                format='MM-DD-YYYY'
                                                disabledDate={(current) => moment().add(-1, 'days') >= current}
                                                onChange={(date, dateString) => handleDateChange(date, dateString, 'start_at')}
                                                defaultValue={permitValues.start_at ? dayjs(permitValues.start_at, 'MM-DD-YYYY') : ''}
                                            /> : null}

                                        {type === 'create' ?
                                            <DatePicker
                                                allowClear={false}
                                                format='MM-DD-YYYY'
                                                disabledDate={(current) => moment().add(-1, 'days') >= current}
                                                onChange={(date, dateString) => handleDateChange(date, dateString, 'start_at')}
                                                defaultValue={permitValues.start_at ? dayjs(permitValues.start_at, 'MM-DD-YYYY') : ''}
                                                value={permitValues.start_at ? dayjs(permitValues.start_at, 'MM-DD-YYYY') : ''}
                                            /> : null}
                                        <span
                                            className={`form-error ${!errList.start_at ? 'ng-hide' : ''}`}
                                            ng-show="(permit.startDatevalid == true && requestPermit.$submitted) || startDateError"
                                        >
                                            This field is required
                                        </span>
                                    </div>
                                    <div className="field-hint permit-days">
                                        {isRush ?
                                            <span
                                                ng-show="showExpeditedServiceWarning"
                                                className="ng-binding"
                                            >
                                                <span className="mpIcons-running rush-booking" />
                                                Additional rush order fee of ${cityDetails?.expedited_service_charge || 30} may be included in your invoice

                                                <span className="tooltip tooltip-top sm-width multiple-line">
                                                    <i className="info-tooltip mpIcons-help" />
                                                    <span className="tooltip-content">
                                                        Yikes, we'll need to move quick! Yonkers prefers a lead time of {cityDetails?.lead_time || 3} business days. If the city approves a permit for your request, an expedite fee of ${cityDetails?.expedited_service_charge || 30} will be added to your invoice.
                                                    </span>
                                                </span>
                                            </span>
                                            : null}
                                        {diffDaysNumber ?
                                            <span className="jsPermitDays hint-from-sm" >
                                                This is a {diffDaysNumber}-day permit request
                                            </span>
                                            : null}
                                    </div>
                                </div>
                                <div className="layout-sm-3 layout-md-2">
                                    <div className="form-group mb-3x">
                                        <label>
                                            End Date <span className="color">*</span>
                                        </label>
                                        {type === 'update' && (permitValues.end_at || permitValues.start_at) ?

                                            <DatePicker
                                                allowClear={false}
                                                format='MM-DD-YYYY'
                                                disabledDate={(current) => !current || current.isBefore(permitValues?.start_at) || permitValues?.start_at < moment().endOf('day')}
                                                onChange={(date, dateString) => handleDateChange(date, dateString, 'end_at')}
                                                defaultValue={permitValues.end_at ? dayjs(permitValues.end_at, 'MM-DD-YYYY') : ''}
                                                value={permitValues.end_at ? dayjs(permitValues.end_at, 'MM-DD-YYYY') : ''}
                                            // defaultValue={permitValues.end_at ? moment(permitValues.end_at, 'YYYY-MM-DD') : ''}
                                            /> : null}

                                        {type === 'create' ?

                                            <DatePicker
                                                allowClear={false}
                                                format='MM-DD-YYYY'
                                                disabledDate={(current) => !current || current.isBefore(permitValues?.start_at) || permitValues?.start_at < moment().endOf('day')}
                                                onChange={(date, dateString) => handleDateChange(date, dateString, 'end_at')}
                                                value={permitValues.end_at ? dayjs(permitValues.end_at, 'MM-DD-YYYY') : ''}
                                            /> : null}
                                        <span
                                            className={`form-error ${!errList.end_at ? 'ng-hide' : ''}`}
                                            ng-show="(permit.endDatevalid == true && requestPermit.$submitted) || endDateError"
                                        >
                                            This field is required
                                        </span>
                                        <span
                                            className={`form-error ${!errList.invalidDate ? 'ng-hide' : ''}`}
                                            ng-show="(permit.endDatevalid == true && requestPermit.$submitted) || endDateError"
                                        >
                                            End date should be greater than start date
                                        </span>
                                    </div>
                                    <div className="field-hint permit-days b-28 hint-only-xs">
                                        <span className="jsPermitDays" />
                                    </div>
                                </div>
                                <div
                                    className="layout-sm-6 layout-md-8 "
                                    ng-show="permit.showsignRemoval || permit.showSiteReview"
                                >
                                    <div className="wrap-sign-site-cb-block">
                                        {cityDetails?.sign_removal ?
                                            <div
                                                className="item-block-sign-site "
                                                ng-show="permit.showsignRemoval"
                                            >
                                                <label
                                                    htmlFor="signremoval"
                                                    className="checkbox tooltip sm-width tooltip-bottom  multiple-line align-left-xs"
                                                >
                                                    <input
                                                        ng-disabled="disableSignRemoveBtn"
                                                        type="checkbox"
                                                        id="signremoval"
                                                        name="sign_removal"
                                                        onChange={handleCheckBoxChange}
                                                        className="ng-pristine ng-untouched ng-valid ng-empty"
                                                        checked={permitValues.sign_removal}
                                                    />
                                                    <span>Sign removal</span>
                                                    {/* ngIf: disableSignRemoveBtn */}
                                                </label>
                                                <span onClick={() => setIsSignModalOpen(true)} className="tooltip tooltip-bottom single-line align-left-xs">
                                                    <i
                                                        className="info-tooltip mpIcons-help"
                                                    />
                                                    <span className="tooltip-content">
                                                        Click here to view details
                                                    </span>
                                                </span>
                                            </div> : null}
                                        {cityDetails?.site_review ?
                                            <div
                                                className="item-block-sign-site "
                                                ng-show="permit.showSiteReview"
                                            >
                                                <label htmlFor="sitereview" className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="sitereview"
                                                        name="site_review"
                                                        className="ng-pristine ng-untouched ng-valid ng-empty"
                                                        onChange={handleCheckBoxChange}
                                                        checked={permitValues.site_review}
                                                    />
                                                    <span>Site review</span>
                                                </label>
                                                <span onClick={() => setIsSignModalOpen(true)} className="tooltip tooltip-bottom single-line align-left-xs">
                                                    <i
                                                        className="info-tooltip mpIcons-help"
                                                    />
                                                    <span className="tooltip-content">
                                                        Click here to view details
                                                    </span>
                                                </span>
                                            </div> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="form-heading">
                                <span>Why</span> do you need the permit?
                            </div>
                            <div className="layout-wrapper medium-grid">
                                <div className="layout-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="Permitreason">
                                            Permit reason <span className="color">*</span>
                                        </label>
                                        <select
                                            name="reason_id"
                                            id="permitReason"
                                            className="default-select-box ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                                            ng-options="eachReason.reason_id as eachReason.reason for eachReason in permit.reasons"
                                            required=""
                                            style={{}}
                                            // defaultValue={permitValues.reason_id}
                                            onChange={handleChange}
                                            value={permitValues.reason_id || ''}
                                        >
                                            {/* <option value="" disabled="" className="">
                                                Permit reason
                                            </option> */}
                                            {reasons.map(reason =>
                                                <option key={reason.reason_id} label={reason.reason} value={reason.reason_id}>
                                                    {reason.reason}
                                                </option>
                                            )}
                                        </select>
                                        <span
                                            className={`form-error ${!errList.reason_id ? 'ng-hide' : ''}`}
                                            ng-show="(requestPermit.reason.$error.required && requestPermit.$submitted )"
                                        >
                                            Select a reason
                                        </span>
                                    </div>
                                </div>
                                <div className="layout-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="MeterNumbers">Meter Numbers</label>
                                        <input
                                            name="meter_numbers"
                                            id="MeterNumbers"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                            onChange={handleChange}
                                            value={permitValues.meter_numbers || ''}
                                        />
                                    </div>
                                </div>
                                <div className="layout-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="shipperName">
                                            Shipper name/PO#
                                            <span className="tooltip tooltip-top">
                                                <i className="info-tooltip mpIcons-help" />
                                                <span className="tooltip-content single-line">
                                                    companies only
                                                </span>
                                            </span>
                                        </label>
                                        <input
                                            name='shipper_name'
                                            id="shipperName"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                            ng-focus="permit.shippervalid=false"
                                            onChange={handleChange}
                                            value={permitValues.shipper_name || ''}
                                        />
                                        <span
                                            className="form-error ng-hide"
                                            ng-show="(permit.shippervalid == true && requestPermit.$submitted)"
                                        >
                                            This field is required
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="layout-sm-4 ng-hide"
                                    ng-show="permit.source == 'CP'"
                                >
                                    <div className="form-group">
                                        <label htmlFor="container_no">No of Containers</label>
                                        <input
                                            id="container_no"
                                            name="container_no"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern"
                                            type="text"
                                            ng-pattern="/^\d+$/"
                                        />
                                        <span
                                            className="form-error ng-hide"
                                            ng-show="requestPermit.container_no.$dirty && requestPermit.container_no.$error.pattern"
                                        >
                                            Invalid data
                                        </span>{" "}
                                    </div>
                                </div>
                                <div
                                    className="layout-sm-4 ng-hide"
                                    ng-show="permit.source == 'CP'"
                                >
                                    <div className="form-group">
                                        <label htmlFor="provider">Provider</label>
                                        <input
                                            id="provider"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="layout-sm-12 layout-md-4">
                                    <div className="form-group">
                                        <label htmlFor="specialRequirement">
                                            Special Requirements <span>(optional)</span>
                                            <span className="tooltip tooltip-top sm-width align-right-xs">
                                                <i className="info-tooltip mpIcons-help" />
                                                <span className="tooltip-content">
                                                    Let us know exactly what you need (footage, special
                                                    location, special times, etc). Please be as concise as
                                                    possible.
                                                </span>
                                            </span>
                                        </label>
                                        <textarea
                                            name='special_requirements'
                                            id="specialRequirement"
                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                            rows={3}
                                            onChange={handleChange}
                                            value={permitValues.special_requirements || ''}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="request-section-heading odd-heading edit-btn-box">
                                CUSTOMER INFORMATION
                                {isLoggedIn ?
                                    <button
                                        mixular="click"
                                        eventlabel="Clicked Edit customer info button in request permit page"
                                        className="btn edit-customer-info xs-rounded-btn active"
                                        type="button"
                                        ng-class="{ 'active' : editForm }"
                                        style={{}}
                                        onClick={() => setIsUserDetailsEdit(!isUserDetailsEdit)}
                                    >
                                        <i className="mpIcons-edit" /> Edit
                                    </button> : null}
                            </div>
                            <div className="layout-wrapper medium-grid">
                                {!isUserDetailsEdit ?
                                    <Card
                                        style={{
                                            width: 300,
                                        }}
                                    >
                                        <p>{`${permitValues.first_name || ''} ${permitValues.last_name || ''}`}</p>
                                        <p>{`${permitValues.billing_address || ''}`}</p>
                                        {permitValues.billing_city ?
                                            <p>{`${permitValues.billing_city || ''}, ${permitValues.billing_zipcode || ''}`}</p> : null}
                                        {permitValues.billing_phone ? <p>Phone: {permitValues.billing_phone || ''}</p> : null}
                                    </Card>

                                    :
                                    <div className="layout-sm-12" ng-show="editForm" style={{}}>
                                        <div className="layout-wrapper small-grid">
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="companyName">Company Name</label>
                                                    <input
                                                        id="companyName"
                                                        className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                                        type="text"
                                                        name="company_name"
                                                        value={permitValues.company_name || ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="firstName">
                                                        First Name <span className="color">*</span>
                                                    </label>
                                                    <input
                                                        id="firstName"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        type="text"
                                                        name="first_name"
                                                        required=""
                                                        value={permitValues.first_name || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.first_name ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.firstname.$invalid && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="lastName">
                                                        Last Name <span className="color">*</span>
                                                    </label>
                                                    <input
                                                        id="lastName"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        type="text"
                                                        name="last_name"
                                                        required=""
                                                        value={permitValues.last_name || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.last_name ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.lastname.$invalid && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="billingAddress">
                                                        Billing Address <span className="color">*</span>
                                                    </label>
                                                    <textarea
                                                        id="billingAddress"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        rows={3}
                                                        name="billing_address"
                                                        required=""
                                                        defaultValue={""}
                                                        value={permitValues.billing_address || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.billing_address ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingaddress.$invalid && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="city">
                                                        City <span className="color">*</span>
                                                    </label>
                                                    <input
                                                        id="city"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        type="text"
                                                        name="billing_city"
                                                        required=""
                                                        value={permitValues.billing_city || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.billing_city ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingcity.$invalid && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="state">
                                                        State <span className="color">*</span>
                                                    </label>
                                                    <select
                                                        name="state_id"
                                                        id="billingstate"
                                                        className="default-select-box ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        ng-options="eachState.state_id as eachState.state_name for eachState in permit.states"
                                                        required=""
                                                        value={permitValues.state_id || ''}
                                                        onChange={handleChange}
                                                    >
                                                        <option
                                                            value=""
                                                            disabled=""
                                                            className=""
                                                            selected="selected"
                                                        >
                                                            Select a state
                                                        </option>
                                                        {states.map(state =>
                                                            <option key={state?.state_id} label={`${state?.state_name}`} value={state?.state_id}>
                                                                {state?.state_name}
                                                            </option>
                                                        )}
                                                    </select>
                                                    <span
                                                        className={`form-error ${!errList.state_id ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingstate.$error.required && requestPermit.$submitted )"
                                                    >
                                                        Select a state
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="zip">
                                                        Postal Code <span className="color">*</span>
                                                    </label>
                                                    <input
                                                        id="billingzip"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern"
                                                        type="text"
                                                        name="billing_zipcode"
                                                        ng-pattern="/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *(\d[A-Z]\d)*)$/"
                                                        required=""
                                                        value={permitValues.billing_zipcode || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.billing_zipcode ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingzip.$error.required && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                    <span
                                                        className={`form-error ${!errList.invalidBillingZip ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingzip.$error.pattern && requestPermit.$submitted)"
                                                    >
                                                        Invalid postal code
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-2">
                                                <div className="form-group">
                                                    <label htmlFor="phone">
                                                        Phone <span className="color">*</span>
                                                    </label>
                                                    <input
                                                        id="phone"
                                                        className="text-field ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                                        type="text"
                                                        name="billing_phone"
                                                        required=""
                                                        value={permitValues.billing_phone || ''}
                                                        onChange={handleChange}
                                                    />
                                                    <span
                                                        className={`form-error ${!errList.billing_phone ? 'ng-hide' : ''}`}
                                                        ng-show="(requestPermit.billingphone.$invalid && requestPermit.$submitted)"
                                                    >
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="layout-sm-2">
                                                <div className="form-group">
                                                    <label htmlFor="ext">Ext </label>
                                                    <input
                                                        id="ext"
                                                        className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                                        type="text"
                                                        name="billing_phone_ext"
                                                        value={permitValues.billing_phone_ext || ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="layout-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="fax">
                                                        Fax <span> (optional)</span>
                                                    </label>
                                                    <input
                                                        id="fax"
                                                        className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                                        type="text"
                                                        name='billing_fax'
                                                        value={permitValues.billing_fax || ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            {!isLoggedIn ?
                                                <div className="layout-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="email">
                                                            Email <span className="color">*</span>
                                                            <span className="tooltip tooltip-top sm-width align-left-xs">
                                                                <i className="info-tooltip mpIcons-help" />
                                                                <span className="tooltip-content">
                                                                    We’re committed to your privacy. MovingPermits uses the information you provide to us to contact you about your permit status. For more information, check out our Terms of Service
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <input
                                                            id="email"
                                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                                            type="text"
                                                            name='email'
                                                            value={permitValues.email || ''}
                                                            onChange={handleChange}
                                                        />
                                                        <span
                                                            className={`form-error ${!errList.email ? 'ng-hide' : ''}`}
                                                            ng-show="(requestPermit.lastname.$invalid && requestPermit.$submitted)"
                                                        >
                                                            This field is required
                                                        </span>

                                                    </div>
                                                </div> : null}
                                            {/* ngIf: ['mover', 'contractor'].indexOf(permit.userDetails.Classification.classification_name) > -1 */}
                                        </div>
                                    </div>}
                                {/*edit form end*/}
                            </div>
                            <div className="request-section-heading odd-heading">
                                PAYMENT INFORMATION
                            </div>
                            {!permitValues.paymentMethod ?
                                <>
                                    <div
                                        className="payment-heading use-card-heading"
                                        ng-hide="showcard==true || permit.paymenttype"
                                    >
                                        <h3>USE YOUR CREDIT CARD</h3>
                                        {/* <label for="creditCard" class="radio-btn">
          <span>I have credit card</span>
      </label> */}
                                    </div>
                                    <div
                                        className="tab credit-card-tabs"
                                        ng-hide="showcard==true || permit.paymenttype"
                                    >
                                        {/*Tab menu start*/}
                                        <ul>
                                            {isLoggedIn ?
                                                <li onClick={() => isLoggedIn ? setIsSavedCards(!isSavedCards
                                                ) : false}>
                                                    <a
                                                        // href="#"
                                                        className={isSavedCards ? "active" : ''}
                                                        style={{}}
                                                    >
                                                        Saved Cards
                                                    </a>
                                                </li> : null}
                                            <li onClick={() => isLoggedIn ? setIsSavedCards(!isSavedCards
                                            ) : false}>
                                                <a
                                                    // href="#"
                                                    ng-class="{ active: isSet(2) }"
                                                    className={!isSavedCards ? "active" : ''}
                                                    style={{}}
                                                >
                                                    Enter a new credit card
                                                </a>
                                            </li>
                                        </ul>
                                        {/*Tab menu end*/}
                                        <div className="tab_container_wrpr">
                                            {/*Tab content 01 start*/}
                                            {isSavedCards ?
                                                <div
                                                    className="tab_container"
                                                >
                                                    {!cardDetails.length ?
                                                        <p
                                                            className="no-saved-cards"
                                                            ng-hide="permit.paymentMethodLength"
                                                        >
                                                            No saved cards.
                                                        </p>
                                                        : null}
                                                    {cardDetails.map(card =>
                                                        <div key={card.customerPaymentProfileId} className="card-item-block" ng-repeat="eachPmntMethod in permit.paymentMethods | orderBy: 'updatedAt':true | limitTo : 5">
                                                            <div className="layout-wrapper medium-grid">
                                                                <div className="layout-sm-5 layout-md-4">
                                                                    <div className="form-group">
                                                                        <label>Card Number</label>
                                                                        <div className="card-number">
                                                                            {console.log('permitValues.authorize_card_id ======', permitValues.authorize_card_id)}
                                                                            {console.log('card.customerPaymentProfileId ======', card.customerPaymentProfileId)}
                                                                            {console.log('card.customerPaymentProfileId22222222 ======', card)}
                                                                            <label className="radio-btn no-label">
                                                                                <input type="radio" name="savedcard" ng-model="permit.savedcard"
                                                                                    onChange={() => handleCardChange(card)}
                                                                                    checked={(permitValues.customerPaymentProfileId === card.customerPaymentProfileId)
                                                                                        || (permitValues.authorize_card_id === card.customerPaymentProfileId)}
                                                                                />
                                                                                <span>no label</span>
                                                                            </label>
                                                                            <input className="text-field cc-number-saved" type="tel" autocomplete="cc-number-saved" value={card?.payment?.creditCard?.cardNumber} disabled="disabled" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="layout-sm-3">
                                                                    <div className="form-group">
                                                                        <label>Expiration Date</label>
                                                                        <input className="text-field cc-exp-saved" autocomplete="off" type="tel" value={card?.payment?.creditCard?.expirationDate} disabled="disabled" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div> :
                                                <div className="tab_container" ng-show="isSet(2)" style={{}}>
                                                    <div className="new-card-item-block">
                                                        <div className="layout-wrapper medium-grid">
                                                            {/* ngIf: permit.invoiceclick */}
                                                            <div
                                                                className="layout-sm-5 layout-md-4 ng-scope"
                                                                ng-if="permit.invoiceclick"
                                                            >
                                                                <div className="form-group">
                                                                    <label htmlFor="creditCard">
                                                                        Card Number <span className="color">*</span>
                                                                    </label>
                                                                    <div className="card-number">
                                                                        <input
                                                                            id="creditCard"
                                                                            className="text-field cc-number ng-pristine ng-untouched ng-valid ng-empty"
                                                                            // type="tel"
                                                                            // autoComplete="cc-number"
                                                                            placeholder="0000-0000-0000-0000"
                                                                            name="card_number"
                                                                            // inputMode="numeric"
                                                                            // pattern="[0-9\s]{13,19}"
                                                                            maxLength="19"
                                                                            onChange={handleChange}
                                                                            onKeyDown={handleKeyDown}
                                                                            value={permitValues.card_number || ''}
                                                                        />
                                                                        <span
                                                                            className={`form-error ${!errList.card_number ? 'ng-hide' : ''}`}
                                                                            ng-show="(permit.cardnumvalid == true && requestPermit.$submitted)"
                                                                        >
                                                                            This field is required
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* end ngIf: permit.invoiceclick */}
                                                            {/* ngIf: permit.invoiceclick */}
                                                            <div
                                                                className="layout-sm-3 ng-scope"
                                                                ng-if="permit.invoiceclick"
                                                            >
                                                                <div className="form-group">
                                                                    <label htmlFor="expirationDate">
                                                                        Expiration Date <span className="color">*</span>
                                                                    </label>
                                                                    <input
                                                                        id="expirationDate"
                                                                        className="text-field cc-exp ng-pristine ng-untouched ng-valid ng-empty"
                                                                        autoComplete="cc-exp"
                                                                        type="tel"
                                                                        placeholder="MM / YY"
                                                                        name='card_expiration_date'
                                                                        onChange={handleChange}
                                                                        onKeyDown={handleKeyDown}
                                                                        maxLength="7"
                                                                        value={permitValues.card_expiration_date || ''}
                                                                    />
                                                                    <span
                                                                        className={`form-error ${!errList.card_expiration_date ? 'ng-hide' : ''}`}
                                                                        ng-show="(permit.expiryvalid == true && requestPermit.$submitted)"
                                                                    >
                                                                        This field is required
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/* end ngIf: permit.invoiceclick */}
                                                            {/* ngIf: permit.invoiceclick */}
                                                            <div
                                                                className="layout-sm-2 ng-scope"
                                                                ng-if="permit.invoiceclick"
                                                            >
                                                                <div className="form-group no-margin">
                                                                    <label htmlFor="securityCode">
                                                                        CVV <span className="color">*</span>
                                                                        <span className="tooltip tooltip-top sm-width align-left-xs">
                                                                            <i className="info-tooltip mpIcons-help" />
                                                                            <span className="tooltip-content">
                                                                                The CVV Number is located on the back of
                                                                                Discover, MasterCard and Visa credit/debit cards
                                                                                and is typically a separate group of 3 digits to
                                                                                the right of the signature strip. American
                                                                                Express credit or debit card it is a 4 digit
                                                                                numeric code.
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        id="securityCode"
                                                                        className="text-field cc-cvc ng-pristine ng-untouched ng-valid ng-empty"
                                                                        autoComplete="off"
                                                                        // type="tel"
                                                                        name='card_security_code'
                                                                        onChange={handleChange}
                                                                        maxLength="4"
                                                                        value={permitValues.card_security_code || ''}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* end ngIf: permit.invoiceclick */}
                                                        </div>
                                                    </div>
                                                </div>}
                                            {/*Tab content 02 end*/}
                                        </div>
                                    </div>
                                </> : null}
                            <div className="payment-heading pay-later">
                                <h3>USE A PAY LATER METHOD</h3>
                                <label
                                    ng-hide="permit.disableInvoice"
                                    htmlFor="payLater"
                                    className="checkbox"
                                    ng-class="{'disabled-radio' :permit.disableInvoice}"
                                >
                                    <input
                                        name="paymentMethod"
                                        id="payLater"
                                        type="checkbox"
                                        ng-disabled="permit.disableInvoice"
                                        className="ng-pristine ng-untouched ng-valid ng-empty"
                                        onChange={handleCheckBoxChange}
                                        checked={permitValues.paymentMethod}
                                    />
                                    <span>I'm a professional mover, please invoice me. </span>
                                </label>
                                <label ng-show="permit.disableInvoice" className="ng-hide">
                                    Your company is required to pay with a credit card
                                </label>
                                <span
                                    className="tooltip tooltip-top sm-width align-left-xs why-not-choose ng-hide"
                                    ng-show="permit.disableInvoice"
                                >
                                    <i className="info-tooltip mpIcons-help" />
                                    <span className="tooltip-content">
                                        Group credit card pay is enabled.
                                    </span>
                                </span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="terms" className="checkbox">
                                    <input
                                        type="checkbox"
                                        id="terms"
                                        name="termsandservice"
                                        required=""
                                        className="ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                        onChange={handleCheckBoxChange}
                                        checked={permitValues.termsandservice}
                                    />
                                    <span>
                                        I have read and agree to the{" "}
                                        <a href="terms" target="_blank">
                                            Terms of Service
                                        </a>
                                    </span>
                                </label>
                                {/* <div ng-show="currentAuth.agreed_toc!=currentToc"> New </div> */}
                                <span
                                    className={`form-error ${!errList.termsandservice ? 'ng-hide' : ''}`}
                                    ng-show="(requestPermit.termsandservice.$invalid && requestPermit.$submitted)"
                                >
                                    Please accept terms of service
                                </span>
                            </div>
                            <div className="form-group">
                                <p className="form-info">
                                    You are <strong>ordering</strong> a permit for specific date
                                    range and address. We highly advise you to double-check the
                                    information above for accuracy prior to clicking the green
                                    button below.
                                </p>
                            </div>
                            {/* ngIf: showCaptcha */}
                            <div className="form-group action-btn-wrap">
                                <input
                                    type="hidden"
                                    autoComplete="off"
                                    className="ng-pristine ng-untouched ng-valid ng-empty"
                                />
                                <input
                                    type="hidden"
                                    autoComplete="off"
                                    className="ng-pristine ng-untouched ng-valid ng-not-empty"
                                    style={{}}
                                />
                                <button
                                    mixular="click"
                                    eventlabel="Clicked Order my Permit button from request permit page"
                                    className="btn secondary-btn submit-btn xs-rounded-btn is-loader ng-binding"
                                    onClick={handleSubmitData}
                                    disabled={disableSubmit}
                                >
                                    {submitButtonText}
                                </button>
                                <button
                                    className="btn cancel-btn"
                                    onClick={handleCancelPermit}
                                >
                                    Cancel
                                </button>
                                <span
                                    ng-show="validationError"
                                    className={`form-error relative request-total-error ${JSON.stringify(errList) === '{}' ? 'ng-hide' : ''}`}
                                >
                                    Oops! Required field(s) missing. See above.
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
                {/*end page page-white*/}
            </div>
            <RushHourPopup isRushModalOpen={isRushModalOpen} handleOk={() => setIsRushModalOpen(false)} cityDetails={cityDetails} />
            <CityDetailsPopup isCityPopupOpen={isCityPopupOpen} handleOk={() => setIsCityPopupOpen(false)} cityDetails={cityDetails} />
            <SignReviewPopup isSignModalOpen={isSignModalOpen} handleCancel={() => setIsSignModalOpen(false)} cityDetails={cityDetails} onSiteSubmit={(data) => handleSiteSignChange(data)} permitValues={permitValues} />

        </section>



    )
}

export default PermitForm