import React, { useEffect, useState } from 'react'
import './signUpForm.css';
import { processDatas, getSignUpErrors } from './authFormUtils'

const PermitInAccountDetailsForm = ({
    handleSubmit,
    submitButtonText = 'Continue',
    handleSignUpSubmit,
    handleBackButton
}) => {

    const [errList, setErrList] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [signUpDetails, setSignUpDetails] = useState({ email: '', password: '', confirm_password: '', agreed_toc: false });

    const handleChange = (e) => {
        if (e.target.name === "agreed_toc") {
            setSignUpDetails({ ...signUpDetails, [e.target.name]: e.target.checked })
        } else {
            setSignUpDetails({ ...signUpDetails, [e.target.name]: e.target.value })
        }
    }

    const handleSignup = async (e) => {
        e.preventDefault();
        let errorData = await getSignUpErrors(signUpDetails)
        console.log('errorData =====', errorData)
        if (JSON.stringify(errorData) === '{}') {
            setDisableSubmit(true)
            await handleSignUpSubmit(signUpDetails)
            setTimeout(() => {
                setDisableSubmit(false)
            }, 3000)

        } else {
            setErrList(errorData)
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        handleBackButton()
    }

    const testimonial = {}
    return (
        <section className="common-content-wrapper common-dark-bg login-wrapper signup-wrapper"
            ng-show="isAllowed(can_register)">
            <div className="layout-container">
                <div className="pos-relative">
                    <aside className="login-block">
                        <h2>Create an account</h2>
                        <p>Please create a new account below.</p>
                        <form className="basic-form" id="userSignup" name="userSignup"
                            novalidate>
                            <div className="form-group">
                                <div className="input-wrap">
                                    <i className="mpIcons-email-bold"></i>
                                    <input onChange={handleChange} value={signUpDetails.email || ''} type="email" name="email" placeholder="Email" className="text-field"
                                        required />
                                </div>
                                <span className={`form-error ${!(errList.email || errList.invalidEmail) ? 'ng-hide' : ''}`}>
                                    {errList.email ? "This field is required" : "Please enter valid email"}
                                </span>
                            </div>
                            <div className="form-group">
                                <div className="input-wrap">
                                    <i className="mpIcons-password"></i>
                                    <input onChange={handleChange} value={signUpDetails.password || ''} type="password" ng-model="password" name="password" placeholder="Password"
                                        className="text-field" required />
                                </div>
                                <span className={`form-error ${!errList.password ? 'ng-hide' : ''}`}>
                                    {errList.password ? "This field is required" : ""}
                                </span>
                            </div>
                            <div className="form-group">
                                <div className="input-wrap">
                                    <i className="mpIcons-password"></i>
                                    <input onChange={handleChange} value={signUpDetails.confirm_password || ''} type="password" ng-model="passwordConfirm" name="confirm_password"
                                        placeholder="Confirm password" className="text-field" required valid-password-c="password" />

                                    <span className={`form-error ${!errList.confirm_password ? 'ng-hide' : ''}`}>
                                        {errList.confirm_password ? "This field is required" : ""}
                                    </span>
                                    <span className={`form-error ${!errList.passwordNotMatch ? 'ng-hide' : ''}`}>
                                        {errList.passwordNotMatch ? "Passwords do not match" : ""}
                                    </span>
                                </div>
                            </div>
                            <div ng-if="showCaptcha" vc-recaptcha theme="'light'" key="model.key" className="form-group" name="recaptche" required ></div>

                            <div className="form-group">
                                <label for="terms" className="checkbox">
                                    <input onChange={handleChange} type="checkbox" id="terms" ng-model="termsandservice" name="agreed_toc" value={signUpDetails.agreed_toc || false}
                                        required />
                                    <span>By clicking Sign Up you are agreeing to the <a href="terms" target="_blank">Terms of
                                        Service</a>.</span>
                                </label>
                                <span className={`form-error ${!errList.agreed_toc ? 'ng-hide' : ''}`}>
                                    {errList.agreed_toc ? "Please accept terms of service" : ""}
                                </span>
                            </div>

                            <div className="form-group login-button">
                                <button
                                    onClick={handleSignup}
                                    className="btn secondary-btn xs-rounded-btn is-loader"
                                >Sign Up<img ng-if="submitting" className="loader-gif"
                                    src="assets/images/default.svg" alt="" /></button>
                                <button
                                    className="btn cancel-btn"
                                    onClick={handleCancel}
                                >
                                    Back
                                </button>
                            </div>
                        </form>
                    </aside>
                    {/* <aside className="login-testimnial-block" >
                        <div className="content-wrapper" ng-if="fromCP">
                            <div className="header-block">
                                <h2 className="title-tex">Welcome ContainerPermit.com<br /> Customers!</h2>
                                <p className="paragraph"><strong>You have been redirected to MovingPermits.com</strong><br /> Please
                                    enroll and fill out the request
                                    form to submit your order for processing.</p>
                            </div>
                            <div className="media-block">
                                <div className="permit-block permit-puller">
                                    <img src="assets/images/permitpuller-logo-2.png" alt="" className="image" />
                                </div>
                                <div className="permit-block container-permit">
                                    <img src="assets/images/containerpermits-logo.png" alt="" className="image" />
                                </div>
                            </div>

                        </div>
                        <div className="tesimonial-slider" ng-hide="fromCP">
                            <ul id="loginTestimonial" ng-if="testimonials.length > 0">
                                <li ng-repeat="testimonial in testimonials track by $index">
                                    <div className="testimonial-wrapper">
                                        <span className="testimonial-quotes mpIcons-quotes-left"></span>
                                        <p>{testimonial.description}

                                            <span className="more-wrap" ng-show="testimonial.description.length>240">
                                                ... <a ui-sref="reviews" className="readmore-btn">More</a>
                                            </span>
                                        </p>
                                        <div className="testimonial-author">{testimonial.author}</div>
                                        <span className="testimonial-quotes mpIcons-quotes-right"></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </aside> */}
                    {/* <div className="pos-absolute">
                        <div className="create-account pos-fixed">
                            <span>Returning Customers</span>
                            <button type="button" className="btn primary-btn xs-rounded-btn" ng-click="redirectToLogin()">Sign In</button>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>



    )
}

export default PermitInAccountDetailsForm