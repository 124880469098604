import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import './permitInformation.css'
import {
    getStates,
    getCities,
    getMe,
    getReasons,
    setPermitInformation,
    getCardDetails,
} from "../../../actions/permits";
import PermitInformationForm from '../../../components/permitInformationForm/permitInformationForm'
import Header from '../../../components/header/header'
import { Spin, notification } from 'antd';
import { TOASTER } from "../../../actions/types";
import { handleRedirectPermits } from "../../../helpers/utils";

const CreatePermits = ({ angularUrl }) => {
    const history = useHistory();
    const [api, contextHolder] = notification.useNotification();
    const [authDetailsState, setAuthDetailsState] = useState({});
    const [loader, setLoader] = useState(true);

    const openNotificationWithIcon = (type, placement, title, description) => {
        api[type]({
            message: title,
            description,
            placement
        });
    };

    const { cities, states, me, reasons, cardDetails, cityDetails, permitSubmit, permitDetails, permitInformation } = useSelector(state => state.permits);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(getReasons());
                const authDetails = JSON.parse(localStorage.getItem('authDetails'));
                await setAuthDetailsState(authDetails);
                if (authDetails && authDetails.token) {
                    await dispatch(getMe());
                    // dispatch(getClassification());
                    // if (authDetails && authDetails.created_from) {
                    //     await dispatch(getPermitDetails(authDetails.created_from));
                    // } else {
                    //     await dispatch(getCardDetails());
                    // }
                }
                await dispatch(getStates());
                await dispatch(getCities());
                await setLoader(false);

                document.title = 'MovingPermits.com - Request permit';
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle errors if necessary
            }
        };

        fetchData(); // Call the async function
    }, [dispatch]);


    useEffect(() => {
        if (permitDetails?.data?.user_id) {
            dispatch(getCardDetails(permitDetails?.data?.user_id));
        }
    }, [permitDetails]);

    // useEffect(() => {
    //     console.log('CCCCCCCCC', window.location.pathname)
    //     if (angularUrl && angularUrl.option_value) {
    //         if (window.location.pathname !== '/permits/create' && window.location.pathname !== '/permits/update') {
    //             window.location.replace(`${angularUrl.option_value}/login`);
    //         }
    //         if (!authDetailsState) {
    //             window.location.replace(`${angularUrl.option_value}/signup?redirectTo=permitCreate`);
    //         }
    //     }
    // }, [angularUrl]);

    useEffect(() => {
        if (permitSubmit?.permit_id) {
            if (permitSubmit.permit_status === "draft") {
                openNotificationWithIcon('success', 'bottomRight', TOASTER.LOGIN_TITLE, TOASTER.LOGIN_MESSAGE)
                setTimeout(() => {
                    // history.push(`/order/${permitSubmit?.permit_id}`);
                    window.location.replace(`${angularUrl}/login?permit_id=${permitSubmit?.permit_id}`);
                    // window.location.replace(`/order/${permitSubmit?.permit_id}`);
                }, 2000)
                return;
            }
            openNotificationWithIcon('success', 'bottomRight', TOASTER.SUCCESS_TITLE, TOASTER.SUCCESS_MESSAGE)
            setTimeout(() => {
                history.push(`/order/${permitSubmit?.permit_id}`);
                // window.location.replace(`/order/${permitSubmit?.permit_id}`);
            }, 1000)
        }
        if (permitSubmit?.failed) {
            openNotificationWithIcon('error', 'bottomRight', TOASTER.ERROR_TITLE, permitSubmit.message || TOASTER.ERROR_MESSAGE)
        }
    }, [permitSubmit]);

    const handleSubmit = (data) => {
        dispatch(setPermitInformation(data))
        history.push(`/permits/account-information`);
    }

    return (
        <>
            {contextHolder}
            {!loader ?
                <>
                    <Header me={me} angularUrl={angularUrl} />
                    <div className="main-layout ng-scope" ui-view="" style={{}}>
                        <section className="breadcrumbs ng-scope">
                            <div className="layout-container">
                                <ul>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => handleRedirectPermits('/')}>
                                            Home
                                        </span>
                                    </li>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => handleRedirectPermits()}>
                                            Permits
                                        </span>
                                    </li>
                                    <li>Request a permit</li>
                                </ul>
                            </div>
                        </section>
                        <PermitInformationForm
                            isLoggedIn={authDetailsState && authDetailsState.token ? true : false}
                            cities={cities ? cities.rows : []}
                            reasons={reasons ? reasons.rows : []}
                            me={me}
                            states={states ? states.rows : []}
                            handleSubmit={handleSubmit}
                            customerProfileId={cardDetails?.profile?.customerProfileId}
                            cardDetails={cardDetails?.profile?.paymentProfiles}
                            cityDetails={cityDetails?.data}
                            submitButtonText='Continue'
                            permitDetails={permitInformation ? permitInformation : {}}
                            type='create'
                        />
                    </div>
                </>
                :
                <Spin className='loader' size="large" />
            }
        </>
    )
}

export default CreatePermits