import React, { useState, useEffect, useRef } from 'react'
import './header.css'
import { handleRedirectPermits, handleLogout } from "../../helpers/utils";


const Header = ({ me = {}, angularUrl, old }) => {
    const wrapperRef = useRef(null);
    const [openDropDown, setOpenDropDown] = useState(false);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpenDropDown(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        };
    }, []);

    return (
        <header className="main-header ng-scope">
            <div className="layout-container" style={{ top: -10 }}>
                <div
                    className="logo heading-pointer"
                    onClick={() => handleRedirectPermits('/', old)}
                >
                    <img src="https://www.movingpermits.com/assets/images/logo.png" alt="" />
                </div>
                {/* </a> */}
                <button className="btn menu-toggler">
                    <i className="mpIcons-menu" />
                </button>
                <nav className="right-menu">
                    <span
                        className="btn secondary-btn xs-rounded-btn"
                    >
                        REQUEST A PERMIT
                    </span>
                    {me?.user_email ?
                        <div className="navRight ng-scope" ng-if="currentAuth.token" style={{}}>
                            <ul className="menu-list">
                                <li className="ng-scope">
                                    <span
                                        className="header-options header-options-underline"
                                        onClick={() => handleRedirectPermits('', old)}
                                    >
                                        Permits
                                    </span>
                                </li>
                                <li>
                                    <span
                                        className="header-options"
                                        onClick={() => handleRedirectPermits('/pricing', old)}
                                    >
                                        Pricing
                                    </span>
                                </li>
                                {me?.Role?.role_name != 'user' ?
                                    <li>
                                        <span
                                            className="header-options"
                                            onClick={() => handleRedirectPermits('/admin', old)}
                                        >
                                            Admin
                                        </span>
                                    </li>
                                : null}
                                {/* <li ng-if="currentAuth.role !='user'">
                                    <a href="admin" ng-class="{ active: isActive('/admin') }">Admin</a>
                                </li> */}
                                <li
                                    className="my-account drop-down"
                                    ref={wrapperRef}
                                >
                                    <span
                                        className="drop-down-label ng-binding"
                                        onClick={() => setOpenDropDown(!openDropDown)}
                                    >
                                        Hello {me?.UserOption?.fullname || me?.UserOption?.first_name || me?.user_email}
                                    </span>
                                    <i className="caret-icon mpIcons-caret-down" />
                                    <ul className={openDropDown ? '' : 'hideDropdown'}>
                                        <li className="current-user">
                                            <span className="ng-binding">Logged as {me?.user_email}</span>
                                        </li>
                                        <li>
                                            <span
                                                onClick={() => handleRedirectPermits('/accounts/edit', old)}
                                                className='dropdown-account'>
                                                Account
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                onClick={() => handleLogout()}
                                                className='dropdown-account'>
                                                Logout
                                            </span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        :
                        <div class="navRight" ng-if="!currentAuth.token">
                            <ul class="menu-list">
                                <li>
                                    <span
                                        className="header-options"
                                        onClick={() => handleRedirectPermits('/pricing', old)}
                                    >
                                        Pricing
                                    </span>
                                </li>
                                <li>
                                    <span
                                        className="header-options"
                                        onClick={() => window.location.replace(`${angularUrl}/login`)}
                                    >Log In</span>
                                </li>
                                <li>
                                    <span
                                        className="header-options"
                                        onClick={() => window.location.replace(`${angularUrl}/signup`)}
                                    >Sign Up</span>
                                </li>
                            </ul>
                        </div>
                    }
                </nav>
            </div>
        </header>
    )
}

export default Header