import moment from 'moment';


export const getErrors = (permitValues) => {
    let errs = {}
    console.log('permitValues.paymentMethod ========', permitValues.paymentMethod)
    if (!permitValues.reason_id) {
        errs.reason_id = true
    }
    if (!permitValues.city_id) {
        errs.city_id = true
    }
    if (!permitValues.street_address) {
        errs.street_address = true
    }
    if (!permitValues.zipcode) {
        errs.zipcode = true
    }
    if (!permitValues.start_at) {
        errs.start_at = true
    }
    if (!permitValues.end_at) {
        errs.end_at = true
    }
    if (permitValues.zipcode) {
        if (permitValues.zipcode.length > 5 || permitValues.zipcode.match("^[0-9]*$") === null) {
            errs.invalidZip = true
        }
    }
    if (!errs.start_at && !errs.end_at) {
        console.log('111111111111111111111111111', moment(permitValues.end_at).diff(moment(permitValues.start_at), 'days'))
        if (moment(permitValues.end_at).diff(moment(permitValues.start_at)) < 0) {
            errs.invalidDate = true
        }
    }

    return errs
}


export const processDatas = (permitValues, isRush, cityDetails) => {
    if (permitValues.end_at) {
        permitValues.end_at = moment(permitValues.end_at, 'MM-DD-YYYY').format('YYYY-MM-DD');
    }
    if (permitValues.start_at) {
        permitValues.start_at = moment(permitValues.start_at, 'MM-DD-YYYY').format('YYYY-MM-DD');
    }
    if(isRush && cityDetails) {
        permitValues.counting_system = cityDetails.counting_system;
        permitValues.expedited_service_charge = cityDetails.expedited_service_charge;
        permitValues.lead_time = cityDetails.lead_time;
    }
    permitValues.payment_type = permitValues.paymentMethod ? '' : 'card'
    permitValues.source_site = 'react'
    return permitValues
}
