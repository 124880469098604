import moment from 'moment';


export const getErrors = (permitValues, isGuest, quickPermit) => {
    let errs = {}
    console.log('permitValues.paymentMethod ========', permitValues.paymentMethod)
    if (!permitValues.first_name) {
        errs.first_name = true
    }
    if (!permitValues.last_name) {
        errs.last_name = true
    }
    if (!permitValues.billing_address) {
        errs.billing_address = true
    }
    if (!permitValues.billing_city) {
        errs.billing_city = true
    }
    if (!permitValues.billing_zipcode) {
        errs.billing_zipcode = true
    }
    if (!permitValues.state_id) {
        errs.state_id = true
    }
    if (!permitValues.billing_phone) {
        errs.billing_phone = true
    }
    if (isGuest && !permitValues.email) {
        errs.email = true
    }
    if (permitValues.billing_zipcode) {
        if (permitValues.billing_zipcode.length > 5 || permitValues.billing_zipcode.match("^[0-9]*$") === null) {
            errs.invalidBillingZip = true
        }
    }
    return errs
}


export const processDatas = (permitValues) => {
    if (permitValues.card_number) {
        permitValues.card_number = permitValues.card_number.replace(/-/g, "");
    }
    if (permitValues.card_security_code) {
        permitValues.card_security_code = permitValues.card_security_code.replace(/\//g, "");
    }
    if (permitValues.card_expiration_date) {
        permitValues.card_expiration_date = permitValues.card_expiration_date.replace(/ /g, "");
    }
    if (permitValues.end_at) {
        permitValues.end_at = moment(permitValues.end_at, 'MM-DD-YYYY').format('YYYY-MM-DD');
    }
    if (permitValues.start_at) {
        permitValues.start_at = moment(permitValues.start_at, 'MM-DD-YYYY').format('YYYY-MM-DD');
    }
    permitValues.payment_type = permitValues.paymentMethod ? '' : 'card'
    permitValues.source_site = 'react'
    return permitValues
}
