import http from "../http-common";
import { objToUrlParams } from "../helpers/utils";

const getStates = () => {
  return http.get("/states");
};
const getSettingList = (data) => {
  return http.get(`/settings/list?${objToUrlParams(data)}`);
};
const getCities = () => {
  return http.get("/city/?nopaging=true");
};

const getAllClassification = () => {
  return http.get("/classification?nopaging=true");
};

const getMe = () => {
  return http.get("/users/me");
};

const getReasons = () => {
  return http.get("/reasons");
};

const getCardDetails = (user_id, isGuest) => {
  return http.get(`/users/get-user-cards?user_id=${user_id || ''}&isGuest=${isGuest || ''}`);
};

const getCityDetails = id => {
  return http.get(`/city/${id}`);
};

const getPermitDetails = id => {
  return http.get(`/permits/${id}`);
};

const getUserDetails = id => {
  return http.get(`/users/${id}`);
};

const submitPermit = data => {
  return http.post("/permits/", data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        const errorMessage = error.response.data.error || error.response.data.message;
        throw errorMessage
      }
    });
}

const updatePermit = (id, data) => {
  return http.put(`/permits/${id}`, data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        const errorMessage = error.response.data.error || error.response.data.message;
        throw errorMessage
      }
    });
}

const get = id => {
  return http.get(`/tutorials/${id}`);
};

const create = data => {
  return http.post("/tutorials", data);
};
const authChangePassword = data => {
  return http.post("/users/auth-change-password", data);
};

const update = (id, data) => {
  return http.put(`/tutorials/${id}`, data);
};

const remove = id => {
  return http.delete(`/tutorials/${id}`);
};

const removeAll = () => {
  return http.delete(`/tutorials`);
};

const findByTitle = title => {
  return http.get(`/tutorials?title=${title}`);
};

const login = data => {
  return http.post("/users/login", data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        const errorMessage = error.response.data.error || error.response.data.message;
        throw errorMessage
      }
    });
}

const signUp = data => {
  return http.post("/users/register", data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        const errorMessage = error.response.data.error || error.response.data.message;
        throw errorMessage
      }
    });
}

const submitPermitStepOne = data => {
  return http.post("/permits/permit-create-step-one", data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        const errorMessage = error.response.data.error || error.response.data.message;
        throw errorMessage
      }
    });
}

const submitPermitStepTwo = data => {
  return http.put(`/permits/permit-create-step-two/${data.permit_id}`, data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        const errorMessage = error.response.data.error || error.response.data.message;
        throw errorMessage
      }
    });
}

const submitPermitMidStep = data => {
  return http.put(`/permits/permit-create-step-one/${data.permit_id}`, data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        const errorMessage = error.response.data.error || error.response.data.message;
        throw errorMessage
      }
    });
}

const PermitService = {
  getSettingList,
  getStates,
  getCities,
  getAllClassification,
  getMe,
  getReasons,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  submitPermit,
  getCardDetails,
  getCityDetails,
  getPermitDetails,
  updatePermit,
  getUserDetails,
  authChangePassword,
  login,
  signUp,
  submitPermitStepOne,
  submitPermitStepTwo,
  submitPermitMidStep,
};

export default PermitService;
