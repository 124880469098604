import { Modal, Button } from 'antd';
import './rushHourPopup.css';
import { useEffect, useState } from 'react';

const RushHourPopup = ({ isRushModalOpen, handleOk, cityDetails }) => {
    const [cityMessage, setCityMessage] = useState('')

    useEffect(() => {
        let message = cityDetails?.expedited_warning
        if(message) {
            message = message.replace("${city}", cityDetails.city_name);
            message = message.replace("${lead_time}", cityDetails?.lead_time || 3);
            message = message.replace("${days}", cityDetails?.lead_time || 3);
            message = message.replace("${rush_fee}", cityDetails?.expedited_service_charge || 30);
            setCityMessage(message)
        }
    }, [cityDetails])

    return (
        <>
            <Modal
                footer={null}
                title="Rush Fee"
                open={isRushModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
            // width={1000}
            >
                <p>{cityMessage}</p>
                <Button className='ok-button' type="primary" onClick={handleOk}>
                    Ok
                </Button>
            </Modal>
        </>
    );
};
export default RushHourPopup;
