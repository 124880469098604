import config from './../config/config.json';
let environment = process.env.REACT_APP_ENVIRONMENT || 'development';


export const GET_SETTINGS = "GET_SETTINGS";
export const GET_STATES = "GET_STATES";
export const GET_CITIES = "GET_CITIES";
export const GET_CLASSIFICATION = "GET_CLASSIFICATION";
export const GET_ME = "GET_ME";
export const GET_REASON = "GET_REASON";
export const SUBMIT_PERMIT = "SUBMIT_PERMIT";
export const GET_CARD_DETAILS = "GET_CARD_DETAILS";
export const GET_CITY_DETAILS = "GET_CITY_DETAILS";
export const GET_PERMIT_DETAILS = "GET_PERMIT_DETAILS";
export const UPDATE_PERMIT = "UPDATE_PERMIT";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const PASSWORD_UPDATE = "PASSWORD_UPDATE";
export const PERMIT_INFORMATION = "PERMIT_INFORMATION";
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const PERMIT_STEPONE = "PERMIT_STEPONE";


export const TOASTER = {
    SUCCESS_TITLE: "SUCCESS",
    SUCCESS_MESSAGE: "Successfully created the permit",
    SUCCESS_UPDATE_MESSAGE: "Successfully updated the permit",
    ERROR_TITLE: "Failed",
    ERROR_MESSAGE: "Failed to create the permit",
    ERROR_UPDATE_MESSAGE: "Failed to update the permit",
    ERROR_PASSWORD: "Failed to update password",
    LOGIN_TITLE: "LOGIN",
    LOGIN_MESSAGE: "Please login to continue",
    LOGIN_ERROR_MESSAGE: "Invalid email or password",
    LOGIN_SUCCESS_MESSAGE: "Successfully Logged in",
    SIGNUP_SUCCESS_MESSAGE: "Successfully Registered",

}

export const BASE_URL = config[environment].backendUrl;
