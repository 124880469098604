import queryString from 'query-string';
import config from '../config/config.json';
let environment = process.env.REACT_APP_ENVIRONMENT || 'development';

export const objToUrlParams = obj => {
    return queryString.stringify(cleanObject(obj));
};

export function cleanObject(obj) {
    for (let propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj;
}

function checkRequiredFields(obj, requiredFields) {
    for (let field of requiredFields) {
        if (!(field in obj) || obj[field] === "") {
            return false;
        }
    }
    return true;
}


export function checkMeDetails(me) {
    let requiredFields = ["first_name", "last_name", "mailing_address", "mailing_city", "mailing_zip", "mailing_state", "phone"];
    if (me?.UserOption) {
        let allFieldsPresentAndNotEmpty = checkRequiredFields(me?.UserOption, requiredFields);
        return allFieldsPresentAndNotEmpty
    }
    return false;
}

const objectToQueryString =  (obj) => {
    return Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
}

export function handleRedirectPermits(path, old) {
    let finalURL = ""
    console.log('old =====', old)
    if(old) {
        if(path) {
            finalURL = `${config[environment].angularUrl}${path}`;
        } else {
            finalURL = `${config[environment].angularUrl}/permits`;
        }
        console.log("finalURL ============", finalURL);
        window.location.replace(finalURL)
        return;
    }
    const authDetails =  JSON.parse(localStorage.getItem('authDetails'));
    let queryString = '';
    if(authDetails) {
        if(!authDetails.full_name) {
            authDetails.full_name = authDetails.name
        }
        queryString = objectToQueryString(authDetails);
    }
    if(path) {
        finalURL = `${config[environment].angularUrl}${path}?${queryString}`;
    } else {
        finalURL = `${config[environment].angularUrl}/permits?${queryString}`;
    }
    console.log("finalURL ============", finalURL);
    window.location.replace(finalURL)
}

export function handleLogout() {
    localStorage.removeItem('authDetails');
    const finalURL = `${config[environment].angularUrl}/?logout=true`;
    window.location.replace(finalURL)

};
