import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import './permitCheckout.css'
import {
    getClassification,
    getMe,
    createPermitStepTwo,
    getCardDetails,
} from "../../../actions/permits";
import PermitCheckoutForm from '../../../components/permitCheckoutForm/permitCheckoutForm'
import Header from '../../../components/header/header'
import { Spin, notification } from 'antd';
import { TOASTER } from "../../../actions/types";
import { checkMeDetails } from '../../../helpers/utils'
import { handleRedirectPermits } from "../../../helpers/utils";

const CreatePermits = ({ isLoggedIn, angularUrl }) => {
    const history = useHistory();
    const [api, contextHolder] = notification.useNotification();
    const [loader, setLoader] = useState(true);

    const openNotificationWithIcon = (type, placement, title, description) => {
        api[type]({
            message: title,
            description,
            placement
        });
    };

    const { cities, states, me, reasons, cardDetails, cityDetails, permitDetails, permitInformation } = useSelector(state => state.permits);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (!permitInformation?.city_id) {
    //         history.push(`/permits/permit-information`);
    //     }
    //     dispatch(getAngularURL({ setting_key: "angular_url" }));
    //     dispatch(getStates());
    //     const authDetails = JSON.parse(localStorage.getItem('authDetails'));
    //     setAuthDetailsState(authDetails)
    //     if (authDetails && authDetails.token) {
    //         dispatch(getClassification());
    //         if (authDetails && authDetails.created_from) {
    //             dispatch(getPermitDetails(authDetails.created_from));
    //         } else {
    //             dispatch(getCardDetails());
    //         }
    //     }
    // }, [dispatch]);


    const init = async () => {
        document.title = 'MovingPermits.com - Request permit';
        if (!permitInformation?.city_id) {
            history.push(`/permits/permit-information`);
        }
        if (isLoggedIn) {
            try {
                await dispatch(getCardDetails());
                dispatch(getClassification());
                const meData = await dispatch(getMe());
                const checkMeFields = checkMeDetails(meData?.data);
                if (!checkMeFields) {
                    history.push(`/permits/account-information`);
                }
                if(meData?.data?.UserOption?.first_name) {
                    const authDetails = await JSON.parse(localStorage.getItem('authDetails'));
                    authDetails.full_name = `${meData?.data?.UserOption?.first_name} ${meData?.data?.UserOption?.last_name}`
                    await localStorage.setItem('authDetails', JSON.stringify(authDetails));
                }
                setLoader(false)
            } catch (error) {
                // Handle error
                console.error('Error fetching data:', error);
            }
        } else {
            try {
                await dispatch(getCardDetails(permitInformation?.user_id, true));
                // dispatch(getClassification());
                // const meData = await dispatch(getMe());
                // const checkMeFields = checkMeDetails(meData?.data);
                // console.log('checkMeFields =======', checkMeFields);

                // if (!checkMeFields) {
                //     history.push(`/permits/account-information`);
                // }
                setLoader(false)
            } catch (error) {
                // Handle error
                console.error('Error fetching data:', error);
            }
        }
    };

    useEffect(() => {
        init()
    }, [dispatch]);

    useEffect(() => {
        if (permitDetails?.data?.user_id) {
            dispatch(getCardDetails(permitDetails?.data?.user_id));
        }
    }, [permitDetails]);

    const handleCheckoutSubmit = async (data) => {
        const permitStepTwo = await dispatch(createPermitStepTwo(data))
        if (permitStepTwo?.data?.permit_id) {
            openNotificationWithIcon('success', 'bottomRight', TOASTER.SUCCESS_TITLE, TOASTER.SUCCESS_MESSAGE)
            setTimeout(() => {
                history.push(`/order/${permitStepTwo?.data?.permit_id}`);
            }, 1000)
        }
        if (permitStepTwo?.data?.failed) {
            openNotificationWithIcon('error', 'bottomRight', TOASTER.ERROR_TITLE, permitStepTwo?.data?.message || TOASTER.ERROR_MESSAGE)
        }
    }

    return (
        <>
            {contextHolder}
            {!loader ?
                <>
                    <Header me={me} angularUrl={angularUrl} />
                    <div className="main-layout ng-scope" ui-view="" style={{}}>
                        <section className="breadcrumbs ng-scope">
                            <div className="layout-container">
                                <ul>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => handleRedirectPermits('/')}>
                                            Home
                                        </span>
                                    </li>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => handleRedirectPermits('/')}>
                                            Permits
                                        </span>
                                    </li>
                                    <li>Request a permit</li>
                                </ul>
                            </div>
                        </section>
                        <PermitCheckoutForm
                            angularUrl={angularUrl}
                            isLoggedIn={isLoggedIn}
                            // isLoggedIn={authDetailsState && authDetailsState.token ? true : false}
                            cities={cities ? cities.rows : []}
                            reasons={reasons ? reasons.rows : []}
                            me={me}
                            states={states ? states.rows : []}
                            handleSubmit={handleCheckoutSubmit}
                            customerProfileId={cardDetails?.profile?.customerProfileId}
                            cardDetails={cardDetails?.profile?.paymentProfiles}
                            cityDetails={cityDetails?.data}
                            submitButtonText='Order my Permit'
                            permitDetails={permitInformation ? permitInformation : {}}
                            type='create'
                        />
                    </div>
                </>
                :
                <Spin className='loader' size="large" />
            }
        </>
    )
}

export default CreatePermits