import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import './orderConfirm.css'
import Header from '../../components/header/header'
import { Spin, notification } from 'antd';
import { authChangePassword } from "../../actions/permits";
import { TOASTER } from "../../actions/types";
import { handleRedirectPermits } from "../../helpers/utils";

const OrderConfirm = ({ angularUrl, isLoggedIn }) => {
    const { permitId } = useParams();
    const dispatch = useDispatch();
    const [password, setPassword] = useState('')
    console.log('permitId ====', permitId)
    const [api, contextHolder] = notification.useNotification();
    const { me, passwordUpdate } = useSelector(state => state.permits);

    const openNotificationWithIcon = (type, placement, title, description) => {
        api[type]({
            message: title,
            description,
            placement
        });
    };

    const handlePasswordChange = (e) => {
        dispatch(authChangePassword({ password, orderId: permitId }));
    }
    const handleChange = (e) => {
        setPassword(e.target.value)
    }
    useEffect(() => {
        console.log('passwordUpdate =======', passwordUpdate)
        if (passwordUpdate?.success) {
            window.location.replace(`${angularUrl}/login`);
        }
        if (passwordUpdate?.failed) {
            openNotificationWithIcon('error', 'bottomRight', TOASTER.ERROR_TITLE, TOASTER.ERROR_PASSWORD)
        }
    }, [passwordUpdate])



    // const handleRedirectPermits =  () => {
    //     const authDetails =  JSON.parse(localStorage.getItem('authDetails'));
    //     const queryString = objectToQueryString(authDetails);
    //     const finalURL = `${angularUrl}/permits?${queryString}`;
    //     console.log("finalURL ============", finalURL);
    //     window.location.replace(finalURL)
    // }

    return (
        <>
            {contextHolder}
            {me ?
                <>
                    <Header me={me} angularUrl={angularUrl} />
                    <div className="main-layout ng-scope" ui-view="" style={{}}>
                        <section className="breadcrumbs ng-scope">
                            <div className="layout-container">
                                <ul>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => handleRedirectPermits('/')}>
                                            Home
                                        </span>
                                    </li>
                                    <li>
                                        <span className='breadCrumbsButton' onClick={() => handleRedirectPermits()}>
                                            Permits
                                        </span>
                                    </li>
                                    <li>Request a permit</li>
                                </ul>
                            </div>
                        </section>
                        <section class="common-content-wrapper common-dark-bg request-permit">
                            <div class="layout-container">
                                <div class="page-title">
                                    <h2 class="content-primary-title clear-margins">Order confirmation</h2>
                                    <span class="content-sub-head">Your permit was successfully requested.</span>
                                </div>
                                <div class="order-confirmation-block">
                                    <h2>Thank you! We're on it.</h2>
                                    <h4></h4>
                                    <h5 class="permit-id">Check Your Permit's Status with Permit #{permitId}</h5>

                                    {/* <p>Currently, your permit application is: <strong>{"{permitDetails.permit_status | capitalize}"}</strong></p> */}
                                    <p>
                                        <strong>Thank you for submitting your permit request.</strong>
                                        In the event you wish to change, cancel, or reschedule your permit order, please email at <a
                                            href="mailto:info@movingpermits.com">info@movingpermits.com.</a> Please keep in mind that you will
                                        be responsible for all Permit Puller, Inc. fees and city fees paid on your behalf until you have
                                        successfully notified us of your changes.</p>
                                    <p><strong>Upon completion of your permit,</strong> you will receive an email from us containing your permit
                                        documents. You can also check the status of your permit requests anytime online at <a
                                            href="www.movingpermits.com" target="_blank">www.movingpermits.com.</a></p>
                                    <p>Should you ever have any questions please do not hesitate to call or email us. Our normal business hours
                                        are Monday to Friday, 9:00 a.m. to 5:00 p.m. EST. We do typically read email during non-business hours.
                                    </p>
                                    <p>We are here to serve you.</p>
                                    {isLoggedIn ?
                                        <div class="button-wrap">
                                            <a
                                                mixular="click"
                                                eventLabel="Clicked 'Request a Permit in 60 seconds' button"
                                                href="/permits/permit-information"
                                                class="btn secondary-btn xs-rounded-btn is-loader">
                                                Request Another Permit
                                            </a>
                                            <button onClick={() => handleRedirectPermits()} class="btn default-border-btn xs-rounded-btn is-loader">
                                                View My Orders
                                            </button>
                                        </div> :
                                        <div class="button-wrap">
                                            <button onClick={() => window.location.replace(`${angularUrl}/`)} class="btn default-border-btn xs-rounded-btn is-loader">
                                                Back to Home
                                            </button>
                                        </div>
                                    }
                                    {/* {!me.angular_url ?
                                        <>
                                            <br />
                                            <br />
                                            <p>
                                                Create a free account! If you would like to view status of your requests, download previous documents and view order details, please create a password below and we will use the email provided on the request to create your account.
                                            </p>
                                            <div className="layout-wrapper medium-grid">
                                                <div className="layout-sm-4 layout-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="Apartment">Enter your password</label>
                                                        <input
                                                            id="password"
                                                            name="password"
                                                            className="text-field ng-pristine ng-untouched ng-valid ng-empty"
                                                            type="password"
                                                            onChange={handleChange}
                                                            value={password || ''}
                                                        />
                                                        <div class="button-wrap" style={{ marginTop: '10px' }}>
                                                            <button
                                                                mixular="click"
                                                                eventlabel="Clicked Order my Permit button from request permit page"
                                                                className="btn secondary-btn submit-btn xs-rounded-btn is-loader ng-binding"
                                                                onClick={handlePasswordChange}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : null} */}
                                </div>
                            </div>
                        </section>
                    </div>
                </>
                :
                <Spin className='loader' size="large" />
            }
        </>
    )
}

export default OrderConfirm