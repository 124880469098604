import React, { useEffect, useState } from 'react'
import './permitCheckoutForm.css';
import { useDispatch } from "react-redux";
import { getErrors, processDatas } from './permitFormUtils'
import moment from 'moment';
import { getCityDetails } from "../../actions/permits";
import RushHourPopup from "../rushHourPopup/rushHourPopup";
import CityDetailsPopup from "../cityDetailsPopup/cityDetailsPopup";
import SignReviewPopup from "../signReviewPopup/signReviewPopup";
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { useHistory } from "react-router-dom";

dayjs.extend(weekday)
dayjs.extend(localeData)

const PermitInformationForm = ({
    reasons = [],
    me,
    cities = [],
    handleSubmit,
    states = [],
    cardDetails = [],
    customerProfileId,
    cityDetails = {},
    permitDetails = {},
    submitButtonText = 'Order my Permit',
    type,
    quickPermit,
    isLoggedIn,
    angularUrl
}) => {
    const history = useHistory();
    const [permitValues, setPermitValues] = useState({ reason_id: '52', sign_removal: false, site_review: false });
    const [isSavedCards, setIsSavedCards] = useState(cardDetails.length ? true : false);
    const [errList, setErrList] = useState({});
    const [isRushModalOpen, setIsRushModalOpen] = useState(false);
    const [isSignModalOpen, setIsSignModalOpen] = useState(false);
    const [isRush, setIsRush] = useState(false);
    const [diffDaysNumber, setDiffDaysNumber] = useState(null);
    const [isCityPopupOpen, setIsCityPopupOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);


    const dispatch = useDispatch();

    useEffect(() => {
        console.log('permitDetails ========, ', permitDetails)
        if (permitDetails && (typeof permitDetails === "object") && (Object.keys(permitDetails).length !== 0)) {
            let prefilledDatas = {
                city_id: permitDetails.city_id,
                street_address: permitDetails.street_address,
                apartment: permitDetails.apartment,
                zipcode: permitDetails.zip_code || permitDetails.zipcode,
                start_at: permitDetails.start_at ? moment(permitDetails.start_at, 'YYYY-MM-DD').format('MM-DD-YYYY') : '',
                end_at: permitDetails.end_at ? moment(permitDetails.end_at, 'YYYY-MM-DD').format('MM-DD-YYYY') : '',
                reason_id: permitDetails.reason_id,
                first_name: permitDetails.first_name || me?.UserOption?.first_name,
                last_name: permitDetails.last_name || me?.UserOption?.last_name,
                billing_address: permitDetails.billing_address || me?.UserOption?.mailing_address,
                billing_city: permitDetails.billing_city || me?.UserOption?.mailing_city,
                billing_zipcode: permitDetails.billing_zipcode || me?.UserOption?.mailing_zip,
                billing_phone: permitDetails.billing_phone || me?.UserOption?.phone,
                billing_phone_ext: permitDetails.billing_phone_ext || me?.UserOption?.phone_ext,
                billing_fax: permitDetails.billing_fax || me?.UserOption?.fax,
                email: permitDetails.email || "",
                created_from: "",
                state_id: permitDetails.state_id || me?.UserOption?.mailing_state,
                paymentMethod: permitDetails.payment_type === 'invoice' ? true : false,
                source: "MP",
                site_review: permitDetails.site_review,
                sign_removal: permitDetails.sign_removal,
                meter_numbers: permitDetails.meter_numbers,
                shipper_name: permitDetails.shipper_name,
                special_requirements: permitDetails.special_requirements,
                company_name: permitDetails.company_name || me?.UserOption?.company_name,
                authorize_card_id: permitDetails.authorize_card_id,
                authorize_customer_id: permitDetails.authorize_customer_id,
                user_id: permitDetails.user_id,
                card_number: '',
                card_expiration_date: '',
                termsandservice: false,
                payment_method_token: permitDetails.authorize_customer_id,
                customerPaymentProfileId: permitDetails.authorize_card_id,
                permit_id: permitDetails.permit_id,
            }
            if (permitDetails.start_at) {
                let date1 = moment(permitDetails.start_at, 'YYYY-MM-DD');
                let date2 = moment();
                let diffDays = date1.diff(date2, 'days')
                if (diffDays < 4) {
                    setIsRush(true)
                }
            }
            if (quickPermit) {
                prefilledDatas.quick_permit = 1
            }
            if (permitDetails.city_id) {
                dispatch(getCityDetails(permitDetails.city_id));
            }
            if (permitDetails.end_at) {
                let date1 = moment(permitDetails.end_at);
                let date2 = moment(permitDetails.start_at);
                let diffDays = date1.diff(date2, 'days')
                setDiffDaysNumber(diffDays + 1)
            }
            setPermitValues({ ...permitValues, ...prefilledDatas })
        } else {
            if (me) {
                let customerDatas = {
                    first_name: me?.UserOption?.first_name,
                    last_name: me?.UserOption?.last_name,
                    billing_address: me?.UserOption?.mailing_address,
                    billing_city: me?.UserOption?.mailing_city,
                    billing_phone_ext: me?.UserOption?.phone_ext,
                    billing_fax: me?.UserOption?.fax,
                    billing_zipcode: me?.UserOption?.mailing_zip,
                    state_id: me?.UserOption?.mailing_state,
                    billing_phone: me?.UserOption?.phone,
                    company_name: me?.UserOption?.company_name,
                    card_number: '',
                    card_expiration_date: ''
                }
                setPermitValues({ ...permitValues, ...customerDatas })
            }
        }
    }, [me, permitDetails])

    const handleKeyDown = (e) => {
        if (e.keyCode === 8) {
            if (permitValues.card_number) {
                if (permitValues.card_number.length === 5 || permitValues.card_number.length === 10 || permitValues.card_number.length === 15) {
                    e.preventDefault();
                    setPermitValues({ ...permitValues, [e.target.name]: permitValues.card_number.slice(0, -2) })
                }
            }
            if (permitValues.card_expiration_date) {
                if (permitValues.card_expiration_date.length === 5) {
                    e.preventDefault();
                    setPermitValues({ ...permitValues, [e.target.name]: permitValues.card_expiration_date.slice(0, -4) })
                }
            }
        }
    };

    const handleCardChange = (card) => {
        setPermitValues({
            ...permitValues,
            payment_method_token: customerProfileId,
            customerPaymentProfileId: card.customerPaymentProfileId,
        })
    }

    const handleChange = (e) => {
        if (e.target.name === 'city_id') {
            dispatch(getCityDetails(e.target.value));
        }
        if (e.target.name === 'card_number') {
            if (e.target.value.match("^[0-9 -]*$") !== null) {
                let value = e.target.value
                if (e.target.value.length === 4 || e.target.value.length === 9 || e.target.value.length === 14) {
                    value = `${e.target.value}-`
                }
                setPermitValues({ ...permitValues, [e.target.name]: value })
            }
        } else if (e.target.name === 'card_expiration_date') {
            if (e.target.value.match("^[0-9  / ]*$") !== null) {
                let value = e.target.value
                if (e.target.value.length === 2) {
                    value = `${e.target.value} / `
                }
                setPermitValues({ ...permitValues, [e.target.name]: value })
            }
        } else if (e.target.name === 'card_security_code') {
            if (e.target.value.match("^[0-9]*$") !== null) {
                setPermitValues({ ...permitValues, [e.target.name]: e.target.value })
            }
        } else {
            setPermitValues({ ...permitValues, [e.target.name]: e.target.value })
        }
    }

    const handleCheckBoxChange = (e) => {
        setPermitValues({ ...permitValues, [e.target.name]: e.target.checked })
    }

    const handleSiteSignChange = (data) => {
        setPermitValues({ ...permitValues, ...data })
        setIsSignModalOpen(false)
    }

    const handleSubmitData = async (e) => {
        e.preventDefault();
        let errorData = await getErrors(permitValues, isSavedCards, isLoggedIn, quickPermit)
        if (JSON.stringify(errorData) === '{}') {
            setDisableSubmit(true)
            const values = JSON.parse(JSON.stringify(permitValues))
            let finalDatas = await processDatas(values, isRush, cityDetails)
            finalDatas.isLoggedIn = quickPermit ? true : isLoggedIn;
            if(permitDetails.permit_status === 'draft') {
                finalDatas.permit_status = permitDetails.permit_status;
            }
            await handleSubmit(finalDatas)
            setTimeout(() => {
                setDisableSubmit(false)
            }, 3000)

        } else {
            setErrList(errorData)
        }
    }

    const handleCancelPermit = (e) => {
        e.preventDefault();
        if(isLoggedIn) {
            history.push(`/permits/permit-information`)
        } else {
            history.push(`/permits/account-information`)
        }
        // if (type === 'update') {
        //     window.location.replace(`${angularUrl}/permits/${permitDetails?.permit_id}`);
        // } else {
        //     window.location.replace(`${angularUrl}/permits`);
        // }
    }

    return (
        <section className="permitCheckout common-content-wrapper common-dark-bg request-permit ng-scope">
            <div className="layout-container">
                {/*start page-title*/}
                <div className="page-title">
                    <h2 className="content-primary-title clear-margins">
                        Request a Permit
                    </h2>
                    <span className="content-sub-head">
                        Fill out the simple form below to get your permit started. We'll
                        contact you if we need more information.
                    </span>
                </div>
                {/*end page-title*/}
                {/*start page page-white*/}
                <div className="permit-steps">
                    {/*permit information content*/}
                    <div className="permit-content">
                        <form
                            // ng-submit="requestPermit.$valid && submitForm()"
                            className="basic-form ng-pristine ng-invalid ng-invalid-required ng-valid-pattern"
                            id="requestPermit"
                            name="requestPermit"
                            noValidate=""
                            autoComplete="off"
                        >
                            <input
                                style={{ display: "none" }}
                                type="text"
                                name="street_address"
                                value={permitValues.street_address || ''}
                            />
                            <input style={{ display: "none" }} type="text" name="apartment" value={permitValues.apartment || ''} />
                            
                            <div className="request-section-heading odd-heading">
                                PAYMENT INFORMATION
                            </div>
                            {!permitValues.paymentMethod ?
                                <>
                                    <div
                                        className="payment-heading use-card-heading"
                                        ng-hide="showcard==true || permit.paymenttype"
                                    >
                                        <h3>USE YOUR CREDIT CARD</h3>
                                          <button
                                                type="button"
                                                className="btn secondary-btn xs-rounded-btn view-city-hints-btn ng-binding "
                                                onClick={() => setIsCityPopupOpen(true)}
                                            >
                                                <span />
                                                View {cityDetails.city_name} Pricing &amp; Details
                                            </button> 
                                            {/* // : null} */}
                                    </div>

                                    <div
                                        className="tab credit-card-tabs"
                                        ng-hide="showcard==true || permit.paymenttype"
                                    >
                                        {/*Tab menu start*/}
                                        <ul>
                                            {/* {isLoggedIn ? */}
                                                <li onClick={() => setIsSavedCards(!isSavedCards)}>
                                                    <a
                                                        // href="#"
                                                        className={isSavedCards ? "active" : ''}
                                                        style={{}}
                                                    >
                                                        Saved Cards
                                                    </a>
                                                </li> 
                                                {/* // : null} */}
                                            <li onClick={() => setIsSavedCards(!isSavedCards)}>
                                                <a
                                                    // href="#"
                                                    ng-class="{ active: isSet(2) }"
                                                    className={!isSavedCards ? "active" : ''}
                                                    style={{}}
                                                >
                                                    Enter a new credit card
                                                </a>
                                            </li>
                                        </ul>
                                        {/*Tab menu end*/}
                                        <div className="tab_container_wrpr">
                                            {/*Tab content 01 start*/}
                                            {isSavedCards ?
                                                <div
                                                    className="tab_container"
                                                >
                                                    {!cardDetails.length ?
                                                        <p
                                                            className="no-saved-cards"
                                                            ng-hide="permit.paymentMethodLength"
                                                        >
                                                            No saved cards.
                                                        </p>
                                                        : null}
                                                    {cardDetails.map(card =>
                                                        <div key={card.customerPaymentProfileId} className="card-item-block" ng-repeat="eachPmntMethod in permit.paymentMethods | orderBy: 'updatedAt':true | limitTo : 5">
                                                            <div className="layout-wrapper medium-grid">
                                                                <div className="layout-sm-5 layout-md-4">
                                                                    <div className="form-group">
                                                                        <label>Card Number</label>
                                                                        <div className="card-number">
                                                                            <label className="radio-btn no-label">
                                                                                <input type="radio" name="savedcard" ng-model="permit.savedcard"
                                                                                    onChange={() => handleCardChange(card)}
                                                                                    checked={(permitValues.customerPaymentProfileId === card.customerPaymentProfileId)
                                                                                        || (permitValues.authorize_card_id === card.customerPaymentProfileId)}
                                                                                />
                                                                                <span>no label</span>
                                                                            </label>
                                                                            <input className="text-field cc-number-saved" type="tel" autocomplete="cc-number-saved" value={card?.payment?.creditCard?.cardNumber} disabled="disabled" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="layout-sm-3">
                                                                    <div className="form-group">
                                                                        <label>Expiration Date</label>
                                                                        <input className="text-field cc-exp-saved" autocomplete="off" type="tel" value={card?.payment?.creditCard?.expirationDate} disabled="disabled" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div> :
                                                <div className="tab_container" ng-show="isSet(2)" style={{}}>
                                                    <div className="new-card-item-block">
                                                        <div className="layout-wrapper medium-grid">
                                                            {/* ngIf: permit.invoiceclick */}
                                                            <div
                                                                className="layout-sm-5 layout-md-4 ng-scope"
                                                                ng-if="permit.invoiceclick"
                                                            >
                                                                <div className="form-group">
                                                                    <label htmlFor="creditCard">
                                                                        Card Number <span className="color">*</span>
                                                                    </label>
                                                                    <div className="card-number">
                                                                        <input
                                                                            id="creditCard"
                                                                            className="text-field cc-number ng-pristine ng-untouched ng-valid ng-empty"
                                                                            // type="tel"
                                                                            // autoComplete="cc-number"
                                                                            placeholder="0000-0000-0000-0000"
                                                                            name="card_number"
                                                                            // inputMode="numeric"
                                                                            // pattern="[0-9\s]{13,19}"
                                                                            maxLength="19"
                                                                            onChange={handleChange}
                                                                            onKeyDown={handleKeyDown}
                                                                            value={permitValues.card_number || ''}
                                                                        />
                                                                        <span
                                                                            className={`form-error ${!errList.card_number ? 'ng-hide' : ''}`}
                                                                            ng-show="(permit.cardnumvalid == true && requestPermit.$submitted)"
                                                                        >
                                                                            This field is required
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* end ngIf: permit.invoiceclick */}
                                                            {/* ngIf: permit.invoiceclick */}
                                                            <div
                                                                className="layout-sm-3 ng-scope"
                                                                ng-if="permit.invoiceclick"
                                                            >
                                                                <div className="form-group">
                                                                    <label htmlFor="expirationDate">
                                                                        Expiration Date <span className="color">*</span>
                                                                    </label>
                                                                    <input
                                                                        id="expirationDate"
                                                                        className="text-field cc-exp ng-pristine ng-untouched ng-valid ng-empty"
                                                                        autoComplete="cc-exp"
                                                                        type="tel"
                                                                        placeholder="MM / YY"
                                                                        name='card_expiration_date'
                                                                        onChange={handleChange}
                                                                        onKeyDown={handleKeyDown}
                                                                        maxLength="7"
                                                                        value={permitValues.card_expiration_date || ''}
                                                                    />
                                                                    <span
                                                                        className={`form-error ${!errList.card_expiration_date ? 'ng-hide' : ''}`}
                                                                        ng-show="(permit.expiryvalid == true && requestPermit.$submitted)"
                                                                    >
                                                                        This field is required
                                                                    </span>
                                                                    <span
                                                                        className={`form-error ${!errList.invalid_card_expiration_date ? 'ng-hide' : ''}`}
                                                                        ng-show="(permit.expiryvalid == true && requestPermit.$submitted)"
                                                                    >
                                                                        This card is expired
                                                                    </span>
                                                                    <span
                                                                        className={`form-error ${!errList.invalid_card_date ? 'ng-hide' : ''}`}
                                                                        ng-show="(permit.expiryvalid == true && requestPermit.$submitted)"
                                                                    >
                                                                        Invalid card details
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/* end ngIf: permit.invoiceclick */}
                                                            {/* ngIf: permit.invoiceclick */}
                                                            <div
                                                                className="layout-sm-2 ng-scope"
                                                                ng-if="permit.invoiceclick"
                                                            >
                                                                <div className="form-group no-margin">
                                                                    <label htmlFor="securityCode">
                                                                        CVV <span className="color">*</span>
                                                                        <span className="tooltip tooltip-top sm-width align-left-xs">
                                                                            <i className="info-tooltip mpIcons-help" />
                                                                            <span className="tooltip-content">
                                                                                The CVV Number is located on the back of
                                                                                Discover, MasterCard and Visa credit/debit cards
                                                                                and is typically a separate group of 3 digits to
                                                                                the right of the signature strip. American
                                                                                Express credit or debit card it is a 4 digit
                                                                                numeric code.
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        id="securityCode"
                                                                        className="text-field cc-cvc ng-pristine ng-untouched ng-valid ng-empty"
                                                                        autoComplete="off"
                                                                        // type="tel"
                                                                        name='card_security_code'
                                                                        onChange={handleChange}
                                                                        maxLength="4"
                                                                        value={permitValues.card_security_code || ''}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* end ngIf: permit.invoiceclick */}
                                                        </div>
                                                    </div>
                                                </div>}
                                            {/*Tab content 02 end*/}
                                        </div>
                                    </div>
                                </> : null}
                            <div className="payment-heading pay-later">
                                <h3>USE A PAY LATER METHOD</h3>
                                <label
                                    ng-hide="permit.disableInvoice"
                                    htmlFor="payLater"
                                    className="checkbox"
                                    ng-class="{'disabled-radio' :permit.disableInvoice}"
                                >
                                    <input
                                        name="paymentMethod"
                                        id="payLater"
                                        type="checkbox"
                                        ng-disabled="permit.disableInvoice"
                                        className="ng-pristine ng-untouched ng-valid ng-empty"
                                        onChange={handleCheckBoxChange}
                                        checked={permitValues.paymentMethod}
                                    />
                                    <span>I'm a professional mover, please invoice me. </span>
                                </label>
                                <label ng-show="permit.disableInvoice" className="ng-hide">
                                    Your company is required to pay with a credit card
                                </label>
                                <span
                                    className="tooltip tooltip-top sm-width align-left-xs why-not-choose ng-hide"
                                    ng-show="permit.disableInvoice"
                                >
                                    <i className="info-tooltip mpIcons-help" />
                                    <span className="tooltip-content">
                                        Group credit card pay is enabled.
                                    </span>
                                </span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="terms" className="checkbox">
                                    <input
                                        type="checkbox"
                                        id="terms"
                                        name="termsandservice"
                                        required=""
                                        className="ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                                        onChange={handleCheckBoxChange}
                                        checked={permitValues.termsandservice}
                                    />
                                    <span>
                                        I have read and agree to the{" "}
                                        <a href={`${angularUrl}/terms`} target="_blank">
                                            Terms of Service
                                        </a>
                                    </span>
                                </label>
                                {/* <div ng-show="currentAuth.agreed_toc!=currentToc"> New </div> */}
                                <span
                                    className={`form-error ${!errList.termsandservice ? 'ng-hide' : ''}`}
                                    ng-show="(requestPermit.termsandservice.$invalid && requestPermit.$submitted)"
                                >
                                    Please accept terms of service
                                </span>
                            </div>
                            <div className="form-group">
                                <p className="form-info">
                                    You are <strong>ordering</strong> a permit for specific date
                                    range and address. We highly advise you to double-check the
                                    information above for accuracy prior to clicking the green
                                    button below.
                                </p>
                            </div>
                            {/* ngIf: showCaptcha */}
                            <div className="form-group action-btn-wrap">
                                <input
                                    type="hidden"
                                    autoComplete="off"
                                    className="ng-pristine ng-untouched ng-valid ng-empty"
                                />
                                <input
                                    type="hidden"
                                    autoComplete="off"
                                    className="ng-pristine ng-untouched ng-valid ng-not-empty"
                                    style={{}}
                                />
                                <button
                                    mixular="click"
                                    eventlabel="Clicked Order my Permit button from request permit page"
                                    className="btn secondary-btn submit-btn xs-rounded-btn is-loader ng-binding"
                                    onClick={handleSubmitData}
                                    disabled={disableSubmit}
                                >
                                    {submitButtonText}
                                </button>
                                <button
                                    className="btn cancel-btn"
                                    onClick={handleCancelPermit}
                                >
                                    Back
                                </button>
                                <span
                                    ng-show="validationError"
                                    className={`form-error relative request-total-error ${JSON.stringify(errList) === '{}' ? 'ng-hide' : ''}`}
                                >
                                    Oops! Required field(s) missing. See above.
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
                {/*end page page-white*/}
            </div>
            <RushHourPopup isRushModalOpen={isRushModalOpen} handleOk={() => setIsRushModalOpen(false)} cityDetails={cityDetails} />
            <CityDetailsPopup isCityPopupOpen={isCityPopupOpen} handleOk={() => setIsCityPopupOpen(false)} cityDetails={cityDetails} />
            <SignReviewPopup isSignModalOpen={isSignModalOpen} handleCancel={() => setIsSignModalOpen(false)} cityDetails={cityDetails} onSiteSubmit={(data) => handleSiteSignChange(data)} permitValues={permitValues} />

        </section>



    )
}

export default PermitInformationForm