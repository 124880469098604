import React, { useEffect, useState } from 'react'
import { Spin } from 'antd';

import config from '../../config/config.json';
let environment = process.env.REACT_APP_ENVIRONMENT || 'development';


const Logout = () => {
    let configObject = config[environment];

    useEffect(() => {
        localStorage.removeItem('authDetails');
        window.location.replace(`${configObject.angularUrl}/`);
    }, [])

    return (
        <Spin className='loader' size="large" />
    )
}

export default Logout